import React, { useState } from "react";
import { Carousel, CarouselControl, CarouselItem } from "reactstrap";

import { useTheme } from "Hooks/useTheme";
import { UpsellProductType } from "Apis/ConfigurationApi";
import { Image } from "Components/Image";
import { RichTextEditor } from "Components/RichTextEditor";
import { createEditorState } from "Components/RichTextEditor/Utils";
import CloseButton from "../../CloseButton";
import Modal from "../../Modal";
import { Logo } from "../../Logo";

import styles from "./styles.module.scss";

interface Props {
    product: UpsellProductType;
    showLogo?: boolean;

    onToggleClose();
}

export const UpsellMoreInformation = ({
    product,
    onToggleClose,
    showLogo = false,
}: Props) => {
    const imagesIndexLength = product.images.length - 1;
    const [activeIndex, setActiveIndex] = useState(0);
    const { getThemeName } = useTheme();
    const hasImages = product.images.length !== 0;
    const next = () => {
        setActiveIndex(activeIndex === imagesIndexLength ? 0 : activeIndex + 1);
    };
    const previous = () => {
        setActiveIndex(activeIndex === 0 ? imagesIndexLength : activeIndex - 1);
    };

    return (
        <Modal
            isOpen
            objectTitle={
                <>
                    {showLogo && <div className={styles.logo}><Logo brand={getThemeName()} /></div>}
                    {!showLogo && <h4 className="mb-0">{product.name}</h4>}
                    <CloseButton onClick={onToggleClose} />
                </>
            }
        >
            <div>
                {showLogo && <h4 className="mb-3">{product.name}</h4>}
                <div>
                    {product.moreInfo && (
                        <RichTextEditor
                            editorReadonlyClassName="border-0"
                            editorState={createEditorState(product.moreInfo)}
                            readonly
                            size="lg"
                        />
                    )}
                </div>
                {hasImages && (
                    <div className="my-4">
                        <div className="font-weight-bold">
                            <h5>{activeIndex + 1} of {product.images.length}</h5>
                        </div>
                        <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                            {product.images.map(item => <CarouselItem key={item}> <Image src={item} alt={product.name} className={styles.img} /> </CarouselItem>)}
                            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                        </Carousel>
                    </div>)}
            </div>
        </Modal>);
};
