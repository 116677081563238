import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { Button, Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import uuid from "uuid";

import { RichTextEditor } from "../RichTextEditor";
import { createEditorState } from "../RichTextEditor/Utils";
import { UpsellItemType, UpsellProductType } from "Apis/ConfigurationApi";
import RadioButton from "Components/RadioButton";
import { formatPriceFrequency } from "Components/UpsellTile/formatFrequency";

import { useUpsell } from "./useUpsell";
import { UpsellMoreInformation } from "./UpsellMoreInformation";
import styles from "./styles.module.scss";

interface UpsellTileProps {
    upsell: UpsellItemType;
    productId: string;
    testId?: string,
}

const UpsellTile = (
    {
        upsell,
        productId,
        testId,
    }: UpsellTileProps,
) => {
    const {
        isInBasket,
        removeProductFromBscBasket,
        addUpsellProductToBscBasket,
    } = useUpsell();
    const [disableButton, setDisableButton] = useState(false);
    const [upSellMoreInfoSelectedProduct, setUpSellMoreInfoSelectedProduct] = useState({ } as UpsellProductType);
    const [upSellShowMoreInfo, setUpsellShowMoreInfo] = useState(false);

    useEffect(() => {
        setDisableButton(!productId);
    }, [productId]);

    const onRemoveProduct = async () => {
        if (productId) {
            removeProductFromBscBasket(productId);
        }
    };

    const onInfoButtonClicked = (value: UpsellProductType) => (e) => {
        setUpSellMoreInfoSelectedProduct(value);
        setUpsellShowMoreInfo(!upSellShowMoreInfo);
        e.stopPropagation();
    };

    const onOptionSelected = async (selectedProductId: string, productName: string, e?) => {
        if (e) { // prevents onCardSelected from also being invoked
            e.stopPropagation();
        }
        if (isInBasket(selectedProductId) && !disableButton) {
            await onRemoveProduct();
        } else {
            addUpsellProductToBscBasket(upsell, selectedProductId);
        }
    };

    const onCardSelected = async (products: UpsellProductType[]) => {
        if (products.length === 1) { // if only product, just call onOptionSelected
            await onOptionSelected(products[0].id, products[0].name);
        } else {
            let removed = false;
            products.forEach(x => { // unselect any selected product
                if (isInBasket(x.id) && !disableButton) {
                    removed = true;
                    onRemoveProduct();
                }
            });
            if (!removed) { // select first item if card clicked on and no selections were just cleared
                await onOptionSelected(products[0].id, products[0].name);
            }
        }
    };

    return (
        <div className="mx-2 my-4 text-main">
            <Card
                role="button"
                className={classnames(styles.card, "shadow m-auto h-100", isInBasket(productId) ? "border border-primary" : "border-0")}
                onClick={() => onCardSelected(upsell.products)}
                data-testid={testId}
            >
                {isInBasket(productId) && <i className={classnames(styles.inBasket, "fa fa-check-circle fa-2x text-primary")} />}
                <CardBody className="d-flex flex-column">
                    <CardTitle tag="h5">{upsell.upsellName}</CardTitle>
                    <CardSubtitle tag="h6" className="mb-3 text-main small flex-grow-1">
                        {upsell.isOnlyImage ? (
                            <div className="tw-pt-3 -tw-mx-5">
                                <div className="tw-flex tw-justify-center">
                                    <picture>
                                        <source srcSet={`${window.cdnUrl}${upsell.imageRelativePath}`} />
                                        <img
                                            src={`${window.cdnUrl}${upsell.imageRelativePath}`}
                                            alt="upsell img"
                                            className="!tw-max-h-72"
                                        />
                                    </picture>
                                </div>
                                <div className="tw-font-semibold tw-pt-4 tw-text-center">Select information icon for further information</div>
                            </div>
                        ) : (
                            <RichTextEditor
                                placeholder=""
                                editorState={createEditorState(upsell.description)}
                                editorReadonlyClassName="pt-3"
                                readonly
                            />
                        )}
                    </CardSubtitle>
                    {upsell.products.map(product => (
                        <Button
                            key={product.id}
                            onClick={(e) => onOptionSelected(product.id, product.name, e)}
                            color="text"
                            block
                            className={classnames(styles.disabledPrice, "m-0 my-1 p-0 border-0")}
                        >
                            <div
                                className={classnames("d-flex justify-content-between p-2 pt-3 rounded", { [styles.productSelected]: isInBasket(product.id) })}
                            >
                                <div className="d-flex text-main">
                                    <RadioButton
                                        checked={isInBasket(product.id)}
                                        id={uuid()}
                                        label=""
                                        onChange={() => onOptionSelected(product.id, product.name)}
                                        className={styles.inputLabel}
                                    />
                                    <h5>£{product.price}</h5>
                                    <span
                                        className={classnames(styles.frequencyText, "small text-muted")}
                                    >{formatPriceFrequency(product.priceFrequency ?? "")}
                                    </span>
                                </div>
                                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                                <div
                                    color="text"
                                    className={classnames(styles.infoButton, "p-0 m-0 border-0 shadow-none")}
                                    onClick={onInfoButtonClicked(product)}
                                >
                                    <i className="fas fa-info-circle text-accent" />
                                </div>
                            </div>
                        </Button>))}
                </CardBody>
            </Card>
            {upSellShowMoreInfo && (
                <UpsellMoreInformation
                    showLogo
                    onToggleClose={() => setUpsellShowMoreInfo(!upSellShowMoreInfo)}
                    product={upSellMoreInfoSelectedProduct}
                />)}
        </div>);
};

export { UpsellTile };
