import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { useTheme } from "../../Hooks/useTheme";

import styles from "./styles.module.scss";

const RadioButton = props => {
    const { getThemeName } = useTheme();
    const radioStyle = `${getThemeName()}-radio`;
    return (
        <div className={classnames(styles.container, props.className)}>
            <div className={classnames(styles[radioStyle], props.disabled ? styles.disabled : undefined)}>
                <input
                    type="radio"
                    checked={props.checked}
                    onChange={props.onChange}
                    id={props.id}
                    name={props.name}
                    disabled={props.disabled}
                    data-testid={props.id}
                />
                {/* eslint-disable-next-line */}
                <label
                    data-testid={`${props.id}-label`}
                    htmlFor={props.id}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        if (!props.disabled) {
                            props.onChange(!props.checked);
                        }
                        return false;
                    }}
                >
                    <span>{props.label}</span>
                </label>
                {props.subText && (
                    <div className="ml-5 text-accent font-weight-bold font-size-14">
                        {props.subText}
                    </div>
                )}
            </div>
        </div>
    );
};

RadioButton.propTypes = {
    className: PropTypes.string,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    subText: PropTypes.string,
};

export default RadioButton;
