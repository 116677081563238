import React from "react";
import { Modal, ModalBody } from "reactstrap";
import PropTypes from "prop-types";

interface ConfirmEmailModalType {
    redirectTo: string;
}

const ConfirmEmailModal = ({ redirectTo }: ConfirmEmailModalType) => (
    <Modal isOpen centered size="md">
        <ModalBody className="p-3 d-flex flex-column justify-content-center">
            <h1 className="m-3 text-bscPrimary text-center font-weight-bold">Welcome to the Business Support Club</h1>
            <p className="mb-5 mx-5 mt-3 text-center">
                Your new account is setup and ready to go.
            </p>

            <div className="d-flex justify-content-center mb-3">
                <a className="btn btn-bscPrimary" href={redirectTo}>Take me to the checkout</a>
            </div>
        </ModalBody>
    </Modal>
);

ConfirmEmailModal.propTypes = {
    redirectTo: PropTypes.string.isRequired,
};

export { ConfirmEmailModal };
