import React, { useCallback, useEffect, useState } from "react";
import classnames from "classnames";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";

interface Props {
    onChange: (event) => any,
    onConfirmedChange: (confirmed, event) => any,
    onBlur?: () => any,
    isRequired?: boolean,
    isRequiredError?: string,
    label: any,
    name?: string,
    value: string,
    placeholder?: string,
    disabled?: boolean,
    error?: string,
    autoFocus: boolean,
    dataTestId?: string,
    validateOnMount?: boolean,
    formFeedbackTop?: boolean,
    confirmed?: boolean,
}

const TextboxWithConfirmation = ({
    onChange,
    onConfirmedChange,
    onBlur,
    isRequired = false,
    isRequiredError = "This field is required",
    label,
    name = "",
    value,
    placeholder,
    error,
    autoFocus = false,
    dataTestId = "text-confirm-field",
    validateOnMount = false,
    formFeedbackTop = false,
    disabled = false,
    confirmed = false,
}: Props) => {
    const [isRequiredValidationFailed, setIsRequiredValidationFailed] = useState(false);
    const [confirmValue, setConfirmValue] = useState("");
    const [canValidate, setCanValidate] = useState(validateOnMount);
    const [canValidateConfirm, setCanValidateConfirm] = useState(validateOnMount);

    useEffect(() => {
        if (canValidate) {
            setIsRequiredValidationFailed(isRequired && (!value || value === ""));
        }
    }, [canValidate, value, isRequired]);

    const onFieldBlur = useCallback(() => {
        setCanValidate(true);
        if (onBlur) {
            onBlur();
        }
    }, []);

    const onConfirmBlur = useCallback(() => {
        setCanValidateConfirm(true);
    }, []);

    const confirmationChanged = (newValue, newConfirmed, event) => {
        if (newValue === newConfirmed && newConfirmed !== "") {
            onConfirmedChange(true, event);
        } else {
            onConfirmedChange(false, event);
        }
    };

    const onFieldChange = (event) => {
        confirmationChanged(event.target.value, confirmValue, event);
        onChange(event);
    };

    const onConfirmChange = (event) => {
        setConfirmValue(event?.target?.value);
        confirmationChanged(value, event?.target?.value, event);
    };

    return (
        <>
            <FormGroup className={classnames("text-box", isRequired && "text-box-required")}>
                {label && <Label>{label}</Label>}
                {formFeedbackTop && (
                    <div className="text-danger font-weight-bolder font-size-12 h-20px mb-1">
                        {error || ((isRequiredValidationFailed) && <>{isRequiredError}</>)}
                    </div>
                )}
                <Input
                    key={disabled ? "disabled" : "enabled"}
                    type="text"
                    name={name}
                    placeholder={`Enter your ${placeholder}`}
                    value={value || ""}
                    invalid={isRequiredValidationFailed || !!error}
                    onChange={onFieldChange}
                    disabled={disabled}
                    onBlur={onFieldBlur}
                    autoFocus={!disabled && autoFocus}
                    data-testid={dataTestId || ""}
                />
                {!formFeedbackTop && (error || isRequiredValidationFailed) && (
                    <FormFeedback className="font-weight-bold">{error || (isRequiredValidationFailed && <>{isRequiredError}</>)}</FormFeedback>
                )}
            </FormGroup>
            <FormGroup className={classnames("text-box", isRequired && "text-box-required")}>
                <Input
                    key={disabled ? "disabledConfirm" : "enabledConfirm"}
                    type="text"
                    name={name}
                    placeholder={`Confirm your ${placeholder}`}
                    value={confirmed ? value : confirmValue}
                    invalid={canValidateConfirm && !confirmed}
                    valid={confirmed}
                    onChange={onConfirmChange}
                    disabled={disabled}
                    onBlur={onConfirmBlur}
                    data-testid={`${dataTestId}-confirm`}
                />
            </FormGroup>
        </>
    );
};

export { TextboxWithConfirmation };
