export const formatPriceFrequency = (priceFrequency) => {
    switch (priceFrequency) {
        case "Weekly":
            return "/week";
        case "Monthly":
            return "/month";
        case "Annually":
            return "/year";
        default:
            return "";
    }
};
