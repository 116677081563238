import React from "react";
import moment from "moment";

import { useCompanyFormationsContext } from "../../Context";
import { ReviewSummaryRow } from "Components/ReviewSummaryRow";
import { formatAddressForDisplay } from "../../Pages/SummaryForm/addressHelper";

const PersonalInformationSummary = () => {
    const context = useCompanyFormationsContext();

    return (
        <div className="mb-4">
            <h5 className="my-4">Your personal information</h5>
            <ReviewSummaryRow
                title="Full name"
                description={`${context.primaryStakeholder.firstName} ${context.primaryStakeholder.lastName}`}
                target="/personal-info"
            />
            <ReviewSummaryRow
                title="Email"
                description={context.formState.emailAddress || ""}
                target="/contact-information"
            />
            <ReviewSummaryRow
                title="Date of birth"
                description={moment(new Date(`${context.primaryStakeholder.dateOfBirth}`))
                    .format("DD/MM/YYYY") || ""}
                target="/personal-info"
            />
            <ReviewSummaryRow
                title="Personal address"
                description={formatAddressForDisplay(context.primaryStakeholder.address)}
                target="/contact-information"
                isAddress
            />
            <ReviewSummaryRow
                title="Country of residence"
                description={context.primaryStakeholder.countryOfResidence || ""}
                target="/personal-info"
            />
            <ReviewSummaryRow
                title="Nationality"
                description={context.primaryStakeholder.nationality || ""}
                target="/personal-info"
            />
        </div>
    );
};

export { PersonalInformationSummary };
