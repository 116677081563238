import React from "react";

import { useCompanyFormationsContext } from "../../Context";
import { ReviewSummaryRow } from "Components/ReviewSummaryRow";
import { StepRoutes } from "Context/StepperState";
import { formatPriceFrequency } from "Components/UpsellTile/formatFrequency";
import { useUpsell } from "../UpsellTile/useUpsell";

interface Props {
    upsellDeleted: any,
}

export const AdditionalPurchasesSummary = ({ upsellDeleted } : Props) => {
    const { removeProductFromBscBasket } = useUpsell();
    const context = useCompanyFormationsContext();
    const deleteUpsell = (upsellId) => {
        removeProductFromBscBasket(upsellId);
        upsellDeleted();
    };
    return (
        <div className="mb-4">
            <h5 className="my-4">Additional purchases</h5>
            {context.config?.upsells.map(upsellPage => upsellPage.upsells.map(upsell => (
                upsell.products.map(product => (
                    context.formState.bscUpsellBasketIds.some(basketId => basketId === product.id) && (
                        <div key={upsell.upsellId}>
                            <ReviewSummaryRow
                                key={upsell.upsellId}
                                title={upsell.upsellName}
                                description={`£${product.price}`}
                                target={upsellPage.pageUri as StepRoutes}
                                canDelete
                                deleteItem={() => deleteUpsell(product.id)}
                            ><span className="small text-muted">{formatPriceFrequency(product.priceFrequency ?? "")}</span>
                            </ReviewSummaryRow>
                        </div>
                    )
                ))
            )))}

        </div>
    );
};
