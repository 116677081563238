import { getToken, removeToken } from "./authentication";
import { getBrowserId } from "./localStorage";

const configuration = {
    domainUrl: "/api/",
    addressEndpoint: "profile",
    termsAndConditionsLink: "",
    privacyPolicyLink: "",
    maxNumberStakeholders: 0,
};

const GENERIC_ERROR = "Unfortunately an error occurred. Please contact us.";

class ErrorInfo extends Error {
    constructor(error, info) {
        super(error);
        this.info = info;
    }
}

const getHeaders = (details) => {
    const token = getToken();
    const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-browserid": getBrowserId(),
    };
    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    return ({
        headers,
        ...details,
        responseType: "json",
    });
};

const parseResponse = async (response) => {
    const contentType = response.headers.get("content-type");
    if (contentType === "text/plain; charset=utf-8") {
        return response.text();
    }
    const json = await response.json();
    if (json.error) {
        throw new ErrorInfo(json.error, json);
    }
    return json;
};

const common = (url, request) => (
    fetch(configuration.domainUrl + url, getHeaders(request))
        .then(async (response) => {
            if (!response.ok) {
                if (response.status >= 405) {
                    const error = await response.json();
                    throw { message: (error && (error.message || error.Message)) || GENERIC_ERROR };
                } else if (response.status === 401) {
                    removeToken();
                    throw { message: response.statusText };
                } else if (response.status === 404) {
                    throw { message: response.statusText };
                } else {
                    throw await parseResponse(response);
                }
            }

            if (response.status === 204) {
                return {};
            }
            return parseResponse(response);
        })
);

const get = url => common(url, "GET");

const post = (url, body) => common(url, {
    method: "POST",
    body: JSON.stringify(body),
});

const del = (url, body) => common(url, {
    method: "DELETE",
    body: JSON.stringify(body),
});

export default {
    get,
    post,
    del,
    GENERIC_ERROR,
    configuration,
};
