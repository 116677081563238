import { Button, Form, FormGroup, Input } from "reactstrap";
import Select from "react-select";
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { useHistory } from "react-router";

import Textbox from "../../Components/Textbox";
import { FormLabel } from "../../Components/FormLabel";
import { useCompanyFormationsContext } from "../../Context";
import { DayMonthYearField } from "../../Components/DayMonthYearField";
import { Stakeholder, titles } from "../../Types/PersonalInformation";
import { PersonalInformationModal } from "../../Modals/PersonalInformationModal";
import { ConfirmCancelModal } from "../../Modals/ConfirmCancelModal";
import { maxBirthDate, minBirthDate } from "../../Utils/dateHelper";
import { useAdditionalStakeholders } from "../../Hooks/useAdditionalStakeholders";
import { StakeholderActionButtons } from "../../Components/StakeholderActionButtons";
import { sort } from "../../Utils/sortingUtil";
import api from "../../Utils/api";

import { countries } from "./countries";
import { nationalities } from "./nationalities";

export const PersonalInformationForm: React.FC = () => {
    const history = useHistory<{ scrollBottom: boolean }>();
    const [allowContinue, setAllowContinue] = useState(false);
    const context = useCompanyFormationsContext();
    const [dobValid, setDobValid] = useState(context.primaryStakeholder.dateOfBirth !== undefined);

    const {
        activeStakeholder,
        modalOpen,
        toggleCreateStakeholderModal,
        onCreateSubmit,
        toggleEditModalOpen,
        onEditSubmit,
        toggleRemoveStakeholderModal,
        onRemoveSubmit,
    } = useAdditionalStakeholders("Director");

    const isInvalid = () => !context.primaryStakeholder.title || !context.primaryStakeholder.firstName || !context.primaryStakeholder.lastName
        || (context.primaryStakeholder.middleName && context.primaryStakeholder.middleName.length < 2)
        || !dobValid
        || !context.primaryStakeholder.nationality || !context.primaryStakeholder.countryOfResidence;

    useEffect(() => {
        context.setProceedLabel("Continue");
    }, []);

    useEffect(() => {
        setAllowContinue(!isInvalid());
        context.setDisableContinueButton(isInvalid());
    }, [isInvalid]);

    const onFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if (allowContinue) {
            context.navigateToStep(context.currentStep.nextPage);
        }
    };

    const onDateChanged = (valid, event) => {
        if (valid) {
            context.changePrimaryStakeholderState("dateOfBirth", moment(event)
                .format("YYYY-MM-DD HH:mm:ss"));
        }
        setDobValid(valid);
    };

    const additionalDirectorsRef = useRef<HTMLHeadingElement>(null);

    useEffect(() => {
        // Issue with scrollIntoView on some browsers so have to use timeout
        // https://github.com/facebook/react/issues/23396#issuecomment-1376887787
        const timer = setTimeout(() => {
            if (history.location?.state?.scrollBottom && additionalDirectorsRef.current) {
                additionalDirectorsRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                });
            }
        });

        return () => clearTimeout(timer);
    }, [history.location?.state]);

    return (
        <div className="footerGap">
            <h3 className="text-header">Director Information</h3>
            <FormLabel className="text-accent d-block mb-4">The director information will be submitted to Companies House and is required to complete the formation
                of your company.
            </FormLabel>
            <h4>Primary Applicant</h4>
            <h5>Legal Name</h5>
            <FormLabel className="text-accent d-block mb-2">Please enter your legal name as it appears on your passport. The information entered here will make up
                your director details.
            </FormLabel>
            <Form onSubmit={onFormSubmit}>
                <Button type="submit" className="d-none" />
                <FormGroup>
                    <FormLabel>Title</FormLabel>
                    <Input
                        autoFocus
                        value={context.primaryStakeholder?.title}
                        type="select"
                        name="select"
                        id="titleSelect"
                        data-testid="titleInput"
                        onChange={(event) => context.changePrimaryStakeholderState("title", event.target.value)}
                    >
                        <option hidden value="">Select your title</option>
                        {titles.map(title => (
                            <option key={title}>{title}</option>
                        ))}
                    </Input>
                </FormGroup>
                <Textbox
                    isRequired
                    label={<FormLabel>First name</FormLabel>}
                    value={context.primaryStakeholder.firstName}
                    onChange={(event) => context.changePrimaryStakeholderState("firstName", event.target.value)}
                    placeholder="Enter your first name"
                    data-testid="firstNameInput"
                />
                <Textbox
                    label={<FormLabel optional>Middle name</FormLabel>}
                    value={context.primaryStakeholder.middleName}
                    onChange={(event) => context.changePrimaryStakeholderState("middleName", event.target.value)}
                    placeholder="Enter your middle name"
                    data-testid="middleNameInput"
                    regex="^.{2,}$"
                    isInvalidError="Please enter your full middle name"
                />
                <Textbox
                    isRequired
                    label={<FormLabel>Last name</FormLabel>}
                    value={context.primaryStakeholder.lastName}
                    onChange={(event) => context.changePrimaryStakeholderState("lastName", event.target.value)}
                    placeholder="Enter your last name"
                    data-testid="lastNameInput"
                />
                <h5 className="text-header mt-4">Date of Birth</h5>
                <FormLabel className="text-accent d-block">Your date of birth is a requirement to complete your director information.</FormLabel>
                <FormGroup className="mt-2">
                    <Button type="submit" className="d-none" />
                    <DayMonthYearField
                        max={maxBirthDate}
                        min={minBirthDate}
                        changeDate={onDateChanged}
                        currentDate={context.primaryStakeholder.dateOfBirth}
                        dataTestId="date-of-birth"
                    />
                </FormGroup>
                <h5 className="text-header mb-3">Citizenship</h5>
                <FormLabel htmlFor="countryOfResidenceInput">Country of residence</FormLabel>
                <FormLabel className="text-accent d-block">This is the country where you live and reside. It may not be the same as your nationality.</FormLabel>
                <Select
                    placeholder="Search..."
                    options={countries.map((x) => ({
                        label: x,
                        value: x,
                    }))}
                    onChange={(event) => context.changePrimaryStakeholderState("countryOfResidence", event.label)}
                    className="my-3"
                    value={context.primaryStakeholder.countryOfResidence ? {
                        label: context.primaryStakeholder.countryOfResidence,
                        value: context.primaryStakeholder.countryOfResidence,
                    } : null}
                    inputId="countryOfResidenceInput"
                />
                <FormLabel htmlFor="nationalityInput">Nationality</FormLabel>
                <FormLabel className="text-accent d-block">Search for your nationality. This is data required by Companies House as part of the formation process.</FormLabel>
                <Select
                    placeholder="Search..."
                    options={nationalities.map((x) => ({
                        label: x,
                        value: x,
                    }))}
                    onChange={(event) => context.changePrimaryStakeholderState("nationality", event.label)}
                    className="my-3"
                    value={context.primaryStakeholder.nationality ? {
                        label: context.primaryStakeholder.nationality,
                        value: context.primaryStakeholder.nationality,
                    } : null}
                    inputId="nationalityInput"
                />
            </Form>
            <h5 ref={additionalDirectorsRef}>Additional Directors</h5>
            {context.formState.stakeholders
                ?.filter(x => !x.isPrimary && x.type === "Director")
                .sort((x, y) => sort<Stakeholder>(x, y, "typeCount", true))
                .map(stakeholder => (
                    <div className="tw-flex tw-flex-row tw-gap-2 tw-my-5 md:tw-w-2/3" key={stakeholder.id}>
                        <div className="tw-flex font-weight-bold tw-items-center">
                            <span>{stakeholder.firstName} {stakeholder.lastName}</span>
                        </div>
                        <StakeholderActionButtons
                            onViewDetails={toggleEditModalOpen(stakeholder)}
                            onRemove={toggleRemoveStakeholderModal(stakeholder)}
                            className="tw-items-center"
                        />
                    </div>
                ))}
            {context.formState.stakeholders.length < api.configuration.maxNumberStakeholders && (
                <button
                    type="button"
                    className="tw-font-semibold tw-py-3 tw-px-5 tw-border-gray-400 tw-border-2 tw-text-gray-500 tw-rounded-md !tw-outline-0"
                    onClick={toggleCreateStakeholderModal}
                    data-testid="add-director-button"
                >
                    Add Director
                </button>
            )}
            {/* Add Modal */}
            <PersonalInformationModal
                onFormSubmit={onCreateSubmit}
                isOpen={modalOpen === "create"}
                toggleModal={toggleCreateStakeholderModal}
                stakeholder={activeStakeholder}
                personType="Director"
                title="Add Director"
                submitButtonText="Add"
            />
            {/* Edit Modal */}
            <PersonalInformationModal
                onFormSubmit={onEditSubmit}
                isOpen={modalOpen === "edit" && !!activeStakeholder}
                toggleModal={toggleEditModalOpen()}
                stakeholder={activeStakeholder}
                personType="Director"
                title="Edit Director"
                cancelButtonText="Close"
                submitButtonText="Update"
            />
            <ConfirmCancelModal
                isOpen={modalOpen === "remove" && !!activeStakeholder}
                text={activeStakeholder && `Are you sure you want to remove ${activeStakeholder.firstName} ${activeStakeholder.lastName}?`}
                onConfirm={onRemoveSubmit(activeStakeholder)}
                onClose={toggleRemoveStakeholderModal()}
            />
        </div>
    );
};
