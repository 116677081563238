import React, { useEffect } from "react";
import { Alert } from "reactstrap";

import { FormLabel } from "../../Components/FormLabel";
import Textbox from "../../Components/Textbox";
import { useCompanyFormationsContext } from "../../Context";
import { Stakeholder } from "../../Types/PersonalInformation";
import { PersonalInformationModal } from "../../Modals/PersonalInformationModal";
import { ConfirmCancelModal } from "../../Modals/ConfirmCancelModal";
import { sort } from "../../Utils/sortingUtil";
import { useAdditionalStakeholders } from "../../Hooks/useAdditionalStakeholders";
import { StakeholderActionButtons } from "../../Components/StakeholderActionButtons";
import api from "../../Utils/api";

const ShareholdingsForm = () => {
    const context = useCompanyFormationsContext();

    const {
        activeStakeholder,
        modalOpen,
        toggleCreateStakeholderModal,
        onCreateSubmit,
        toggleEditModalOpen,
        onEditSubmit,
        toggleRemoveStakeholderModal,
        onRemoveSubmit,
    } = useAdditionalStakeholders("Shareholder");

    const onChange = (shareHolder: Stakeholder) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const shareCountSubmitted = Number(event.target.value) ?? 0;
        const shareHoldersUpdated: Stakeholder[] = context.formState
            .stakeholders
            .map(x => {
                if (shareHolder.id === x.id) {
                    return {
                        ...x,
                        [event.target.name]: shareCountSubmitted,
                        personSignificantControl: shareCountSubmitted > 25,
                    };
                }

                return x;
            });

        context.changeFormState("stakeholders", shareHoldersUpdated);
    };

    const renderListing = (stakeholder: Stakeholder) => (
        <div className="tw-flex tw-flex-row tw-space-x-2 tw-items-center" key={stakeholder.id}>
            <Textbox
                key={`${stakeholder.type}-${stakeholder.typeCount}`}
                isRequired
                label={(
                    <FormLabel>
                        {stakeholder.type === "Director" && stakeholder.typeCount === 1
                            ? "Primary Applicant number of shares"
                            : `${stakeholder.type} ${stakeholder.typeCount} number of shares`}
                    </FormLabel>
                )}
                value={String(stakeholder.shareCount)}
                onChange={onChange(stakeholder)}
                name="shareCount"
                placeholder="Percentage shares"
                regex={stakeholder.type === "Director" ? "^([0-9]|[1-9]\\d|100)$" : "^([1-9]|[1-9]\\d|100)$"}
                isInvalidError={`Please enter a whole number between ${stakeholder.type === "Director" ? "0" : "1"} and 100`}
                className="tw-w-60"
                data-testid={`${stakeholder.type}-${stakeholder.typeCount}-share-count`}
            />
            {stakeholder.type === "Shareholder" && (
                <StakeholderActionButtons
                    onViewDetails={toggleEditModalOpen(stakeholder)}
                    onRemove={toggleRemoveStakeholderModal(stakeholder)}
                    buttonClassName="tw-mt-2.5"
                    dataTestId={`${stakeholder.type}-${stakeholder.typeCount}-actions`}
                />
            )}
        </div>
    );

    const totalShareCount = Number(context.formState.stakeholders.reduce((accumulator, next) => Number(accumulator) + Number(next.shareCount), 0));
    const stakeholders = context.formState.stakeholders;

    useEffect(() => {
        let invalidState: boolean = totalShareCount !== 100;
        stakeholders.forEach(stakeholder => {
            if (stakeholder.type === "Shareholder" && !/^([1-9]|[1-9]\d|100)$/.test(String(stakeholder.shareCount))) {
                invalidState = true;
            }
            if (stakeholder.type === "Director" && !/^([0-9]|[1-9]\d|100)$/.test(String(stakeholder.shareCount))) {
                invalidState = true;
            }
        });
        context.setDisableContinueButton(invalidState);
    }, [totalShareCount, stakeholders.map(x => x.shareCount)]);

    return (
        <>
            <h3 className="text-header" data-testid="title">Shareholdings</h3>
            <FormLabel className="text-accent d-block mb-4">
                All our companies are formed with a standardised shareholding of 100 shares.
                This means you can allocate a portion of shares to each director which will correspond
                with their ownership share of the company (One share =1%)
            </FormLabel>
            {/* Directors */}
            {context.formState.stakeholders
                .filter(x => x.type === "Director")
                .sort((x, y) => sort<Stakeholder>(x, y, "typeCount", true))
                .map(director => renderListing(director))}
            {/* Shareholders */}
            {context.formState.stakeholders
                .filter(x => x.type === "Shareholder")
                .sort((x, y) => sort<Stakeholder>(x, y, "typeCount", true))
                .map(shareholder => renderListing(shareholder))}
            {/* Add Modal */}
            <PersonalInformationModal
                onFormSubmit={onCreateSubmit}
                isOpen={modalOpen === "create"}
                toggleModal={toggleCreateStakeholderModal}
                stakeholder={activeStakeholder}
                personType="Shareholder"
                title="Add Shareholder"
                submitButtonText="Add"
            />
            {/* Edit Modal */}
            <PersonalInformationModal
                onFormSubmit={onEditSubmit}
                isOpen={modalOpen === "edit" && !!activeStakeholder}
                toggleModal={toggleEditModalOpen()}
                stakeholder={activeStakeholder}
                personType="Shareholder"
                title="Edit Shareholder"
                cancelButtonText="Close"
                submitButtonText="Update"
            />
            <ConfirmCancelModal
                isOpen={modalOpen === "remove" && !!activeStakeholder}
                text={activeStakeholder && `Are you sure you want to remove ${activeStakeholder.firstName} ${activeStakeholder.lastName}?`}
                onConfirm={onRemoveSubmit(activeStakeholder)}
                onClose={toggleRemoveStakeholderModal()}
            />
            {context.formState.stakeholders.length < api.configuration.maxNumberStakeholders && (
                <button
                    type="button"
                    className="tw-font-semibold tw-py-3 tw-px-5 tw-border-gray-400 tw-border-2 tw-text-gray-500 tw-rounded-md !tw-outline-0"
                    onClick={toggleCreateStakeholderModal}
                >
                    Add shareholder
                </button>
            )}
            {totalShareCount !== 100 && (
                <Alert color="danger" className="tw-mt-5">
                    The shareholder split must total 100 shares.
                </Alert>
            )}
        </>
    );
};

export { ShareholdingsForm };
