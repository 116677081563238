import { v4 } from "uuid";

import { Address } from "./Address";

export type StakeHolderType = "Director" | "Shareholder" | "PersonSignificantControl";

export interface Stakeholder extends PersonalInformation {
    mobileNumber?: string;
    id: string,
    type: StakeHolderType,
    typeCount: number,
    isPrimary: boolean,
    shareCount: number,
    personSignificantControl: boolean,
    rightToAppointAndRemoveDirectors: boolean,
}

export interface PersonalInformation {
    title?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    dateOfBirth?: string;
    countryOfResidence?: string;
    nationality?: string;
    address: Address;
}

export const defaultStakeholder = (type: StakeHolderType): Stakeholder => ({
    id: v4(),
    title: "",
    firstName: "",
    middleName: "",
    lastName: "",
    dateOfBirth: "",
    countryOfResidence: "",
    nationality: "",
    shareCount: 0,
    address: {} as Address,
    type,
    typeCount: 1,
    isPrimary: false,
    personSignificantControl: false,
    rightToAppointAndRemoveDirectors: false,
    mobileNumber: "",
});

export const titles = ["Mr.", "Mrs.", "Ms.", "Miss", "Sir", "Dr."];

export type PersonalInformationModalType = "create" | "edit" | "remove";
