export enum SecurityQuestionKeys {
    TelephoneNumber = "TelephoneNumber",
    FathersFirstName = "FathersFirstName",
    PassportNumber = "PassportNumber",
    TownOfBirth = "TownOfBirth",
    MothersMaidenName = "MothersMaidenName",
}

export const questions = [
    {
        label: "Mobile number",
        value: SecurityQuestionKeys.TelephoneNumber,

    },
    {
        label: "Father's first name",
        value: SecurityQuestionKeys.FathersFirstName,
        disabled: false,
    },
    {
        label: "Passport number",
        value: SecurityQuestionKeys.PassportNumber,
    },
    {
        label: "Town of birth",
        value: SecurityQuestionKeys.TownOfBirth,
    },
    {
        label: "Mother's maiden name",
        value: SecurityQuestionKeys.MothersMaidenName,
    }];
