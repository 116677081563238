import React, { useEffect, useState } from "react";
import store from "store";
import CookieConsent from "react-cookie-consent";
import { hotjar } from "react-hotjar";
import { Route, Router, Switch } from "react-router";
import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/regular";
import "@fortawesome/fontawesome-free/js/brands";
import Div100vh from "react-div-100vh";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";

import { CompanyFormationsConfigResponse, getConfiguration, LocalConfig } from "Apis/ConfigurationApi";
import { AppRouter } from "AppRouter";
import { Spinner } from "Components/Spinner";
import { useTheme } from "Hooks/useTheme";
import { SuazToBscSignUpBridge } from "Pages/SuazToBscSignUpBridge";
import api from "Utils/api";
import { createBrowserId, getBrowserId } from "Utils/localStorage";

import { CompanyFormationsProvider } from "./Context";
import { RaygunLogging } from "./Components/RaygunLogging";
import history from "./appHistory";

import "./Styles/tailwind.generated.css";
import "./Styles/global.scss";

declare global {
    interface Window {
        cdnUrl: string;
    }
}

const App = () => {
    const acceptCookiesStoreKey = "__companyFormations.acceptCookiesKey";
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState("");
    const [config, setConfig] = useState<CompanyFormationsConfigResponse | null>(null);
    const [gtmParams, setGtmParams] = useState<any>({});
    const { loadTheme } = useTheme();

    const getLocalConfig = () => fetch("/config.json") // Get local config such as the backend Api domain
        .then(async response => {
            if (response.ok) {
                const localConfig = await response.json() as LocalConfig;
                api.configuration.domainUrl = localConfig.domainUrl;
                api.configuration.addressEndpoint = "suaz";
                api.configuration.termsAndConditionsLink = localConfig.termsAndConditionsLink;
                api.configuration.privacyPolicyLink = localConfig.privacyPolicyLink;
                api.configuration.maxNumberStakeholders = localConfig.maxNumberStakeholders;
                return;
            }
            throw "Failed get local config";
        });

    const getConfigFromApi = () => getConfiguration(window.location.origin)
        .then(response => {
            window.cdnUrl = response.configuration.bscCdnUrl;
            hotjar.initialize(response.configuration.hotjarId, response.configuration.hotjarSnippetVersion);
            setConfig(response);

            loadTheme(response, () => {
                setIsLoading(false);
            });

            setGtmParams({
                id: response.configuration.gtmKey,
            });
        })
        .catch((err) => {
            document.title = "";
            setIsLoading(false);
            setError(err.message);
        });

    useEffect(() => {
        const browserId = getBrowserId();

        if (!browserId) {
            createBrowserId();
        }

        getLocalConfig()
            .then(getConfigFromApi);
    }, []);

    return (
        <>
            {
                isLoading && (
                    <Div100vh style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100vw",
                        justifyContent: "center",
                    }}
                    > {/* Using inline style above because config now loads css */}
                        <Spinner size={60} />
                    </Div100vh>)
            }
            {
                error
                && (
                    <div className="pt-5 mt-5 d-flex justify-content-center text-danger">
                        <h5>{error || "Unable to load"}</h5>
                    </div>
                )
            }
            {
                !error && !isLoading && (
                    <GTMProvider state={gtmParams}>
                        <CompanyFormationsProvider config={config}>
                            <RaygunLogging />
                            {!store.get(acceptCookiesStoreKey) && (
                                <CookieConsent
                                    location="top"
                                    buttonText="I Accept"
                                    cookieName="suaz-cookie"
                                    style={{ background: "#2B373B" }}
                                    onAccept={() => store.set(acceptCookiesStoreKey, true)}
                                    buttonStyle={{
                                        color: "#4e503b",
                                        fontSize: "13px",
                                    }}
                                    expires={150}
                                >
                                    This website uses cookies to enhance the user experience.
                                </CookieConsent>)}
                            <Router history={history}>
                                <Switch>
                                    <Route
                                        path="/sign-up"
                                        exact
                                        render={() => (
                                            <SuazToBscSignUpBridge
                                                bscUrl={config?.configuration?.bscUrl!}
                                                cdnUrl={config?.configuration?.bscCdnUrl!}
                                            />
                                        )}
                                    />
                                    <Route render={() => <AppRouter />} />
                                </Switch>
                            </Router>
                        </CompanyFormationsProvider>
                    </GTMProvider>)
            }
        </>);
};

export default App;
