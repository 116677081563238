import React from "react";
import classnames from "classnames";
import { Popover, PopoverBody } from "reactstrap";

import { Visible } from "Components/Visible";
import { useTheme } from "Hooks/useTheme";
import CloseButton from "../CloseButton";
import Modal from "../Modal";
import { Logo } from "../Logo";
import { ClickAwayListener } from "Components/ClickAwayListener";

import styles from "./styles.module.scss";

interface Props {
    showModal: () => void,
    hideModal: () => void,
    title?: string,
    items?: string[],
    showLogo?: boolean,
    targetId: string,
    show: boolean,
}

const CheckListModal = ({ title, showModal, hideModal, items, showLogo = false, targetId, show } : Props) => {
    const { getThemeName } = useTheme();
    const toggle = () => {
        if (show) {
            hideModal();
        } else {
            showModal();
        }
    };
    const renderForMobile = () => (
        <>
            <Modal
                isOpen={show}
                objectTitle={
                    <>
                        {showLogo && <div className={styles.logo} data-testid="modal-logo"><Logo brand={getThemeName()} /></div>}
                        {!showLogo && <h4 className="mb-0">{title}</h4>}
                        <CloseButton onClick={hideModal} />
                    </>
                }
            >
                {showLogo && <h4 className="mb-3">{title}</h4>}
                <ul className="fa-ul">
                    {items?.map(x => (
                        <li key={x}>
                            <i className={classnames(styles.listIcon, "fa-li fa fa-check-circle text-primary")} />
                            {x}
                        </li>
                    ))}
                </ul>
            </Modal>
        </>
    );
    const renderForDesktop = () => (
        <Popover placement="bottom-start" isOpen={show} target={targetId} fade={false} toggle={toggle}>
            <PopoverBody className="shadow-lg">
                <ClickAwayListener onClickAway={hideModal}>
                    <div className="d-flex justify-content-end m-2">
                        <ul className="fa-ul mb-0">
                            {items?.map(x => (
                                <li key={x}>
                                    <i className={classnames(styles.listIcon, "fa-li fa fa-check-circle text-primary")} />
                                    {x}
                                </li>
                            ))}
                        </ul>
                    </div>
                </ClickAwayListener>
            </PopoverBody>
        </Popover>
    );
    return (
        <>
            <Visible below="Sm">
                {renderForMobile()}
            </Visible>
            <Visible forAndAbove="Sm">
                {renderForDesktop()}
            </Visible>
        </>
    );
};

export { CheckListModal };
