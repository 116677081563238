import React from "react";
import PropTypes from "prop-types";
import classname from "classnames";

import { useTheme } from "../../Hooks/useTheme";
import { propsWithout } from "../../Utils/filterProps";

import styles from "./styles.module.scss";

const Checkbox = props => {
    const { getThemeName } = useTheme();
    const checkboxStyle = `${getThemeName()}-checkbox`;
    return (
        <div className={classname(styles.container, props.className)}>
            <div className={classname(styles[checkboxStyle], styles[props.alignment])}>
                <input
                    {...propsWithout(props, ["label"])}
                    type="checkbox"
                    checked={props.checked}
                    onChange={() => {
                        if (!props.disabled) {
                            props.onChange(!props.checked);
                        }
                    }}
                    id={props.id}
                    disabled={props.disabled}
                />

                {/* The label is the item that is clicked to activate the Checkbox so to allow us to have the checkbox inside a button, we need to cancel the event propagation */}
                {props.label ? (
                    // eslint-disable-next-line
                    <label
                        className="w-100"
                        htmlFor={props.id}
                        data-testid={props.testId}
                        onClick={(e) => {
                            if (!props.disabled) {
                                e.stopPropagation();
                                e.preventDefault();
                                props.onChange(!props.checked);
                            }
                            return false;
                        }}
                    >
                        {(typeof props.label) === "string" ? <span>{props.label}</span> : props.label}
                    </label>
                ) : (
                    // eslint-disable-next-line
                    <label
                        htmlFor={props.id}
                        data-testid={props.testId}
                        onClick={(e) => {
                            if (!props.disabled) {
                                e.stopPropagation();
                                e.preventDefault();
                                props.onChange(!props.checked);
                            }
                            return false;
                        }}
                    />
                )}
            </div>
        </div>
    );
};

Checkbox.propTypes = {
    alignment: PropTypes.oneOf(["left", "right"]),
    className: PropTypes.string,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    disabled: PropTypes.bool,
    testId: PropTypes.string,
};

Checkbox.defaultProps = {
    alignment: "left",
    testId: "checkbox",
};

export default Checkbox;
