export enum UkAddressCountry {
    England = "England",
    Wales = "Wales",
    Scotland = "Scotland",
    NorthernIreland = "Northern Ireland",
}

export interface Address {
    premise: string,
    addressLine1: string;
    addressLine2: string;
    city: string;
    postCode: string;
    country: UkAddressCountry;
}
