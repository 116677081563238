import React, { useEffect, useState } from "react";

import RadioButton from "../../Components/RadioButton";
import { useCompanyFormationsContext } from "../../Context";
import { AddressLookUp } from "Components/AddressLookup";
import { useUpsell } from "../../Components/UpsellTile/useUpsell";
import { UpsellTile } from "../../Components/UpsellTile";
import { FormLabel } from "../../Components/FormLabel";
import { AddressInput } from "../../Components/AddressInput";
import { AddressLookupState } from "../../Components/AddressLookup/address.instance";
import { Address, UkAddressCountry } from "../../Types/Address";

import { CountryOfRegistrationEnum } from "./CountryOfRegistrationEnum";
import { VirtualOfficeAddress } from "./VirtualOfficeAddress";
import { OfficeAddressKind } from "./OfficeAddressType";

export const OfficeAddressForm = () => {
    const context = useCompanyFormationsContext();
    const {
        getVirtualOfficeUpsellProducts,
        getUpsellsByPage,
        isInBasket,
        getUpsellProductIdIfInBasket,
    } = useUpsell();
    const virtualOfficeProducts = getVirtualOfficeUpsellProducts() ?? [];
    const upsells = getUpsellsByPage();
    const [addressState, setAddressState] = useState(AddressLookupState.Search);
    const [isValidAddress, setIsValidAddress] = useState(false);

    const canMoveToNextStep = () => {
        if (context.formState.officeAddressType === OfficeAddressKind.VirtualAddress) {
            return virtualOfficeProducts.some(x => isInBasket(x.id));
        }
        if (context.formState.officeAddressType === OfficeAddressKind.NewAddress) {
            return context.formState.officeAddress?.country;
        }
        return context.formState.officeAddressType === OfficeAddressKind.ExistingAddress;
    };

    useEffect(() => {
        context.setDisableContinueButton(!canMoveToNextStep());
    }, [canMoveToNextStep]);

    const setCountryOfRegistration = (ukAddressCountry: UkAddressCountry | undefined) => {
        if (ukAddressCountry === UkAddressCountry.NorthernIreland) {
            context.changeFormState("countryOfRegistrationKind", CountryOfRegistrationEnum.NorthernIreland);
        } else if (ukAddressCountry === UkAddressCountry.Scotland) {
            context.changeFormState("countryOfRegistrationKind", CountryOfRegistrationEnum.Scotland);
        } else {
            context.changeFormState("countryOfRegistrationKind", CountryOfRegistrationEnum.EnglandAndWales);
        }
    };

    const setAddressCountry = (countryValue) => {
        context.changeFormState("officeAddress", {
            ...context.formState.officeAddress,
            country: UkAddressCountry[countryValue],
        });
        setCountryOfRegistration(UkAddressCountry[countryValue]);
    };

    const setVirtualAddress = () => {
        context.changeFormState("officeAddressType", OfficeAddressKind.VirtualAddress);
        context.changeFormState("countryOfRegistrationKind", CountryOfRegistrationEnum.EnglandAndWales);
    };

    const setNewAddress = () => {
        context.changeFormState("officeAddressType", OfficeAddressKind.NewAddress);
    };

    const setExistingAddress = () => {
        context.changeFormState("officeAddressType", OfficeAddressKind.ExistingAddress);
        setCountryOfRegistration(context.primaryStakeholder.address?.country);
    };

    const onSaveSearchedAddress = (newAddress: Address) => {
        context.changeFormState("officeAddress", newAddress);
        setIsValidAddress(true);
        setAddressState(AddressLookupState.ReadOnly);
    };

    const onSwitchToManualAddressClick = () => {
        setIsValidAddress(false);
        setAddressState(AddressLookupState.Manual);
    };

    const onManualAddressChange = (newAddress: Address, isValid: boolean) => {
        setIsValidAddress(isValid);
        context.changeFormState("officeAddress", newAddress);
    };
    const onSaveManualAddressClick = () => setAddressState(AddressLookupState.ReadOnly);

    const OnCancelManualAddressClick = () => setAddressState(AddressLookupState.Search);

    const onEditSavedAddressClick = () => setAddressState(AddressLookupState.Manual);

    const onRemoveSavedAddressClick = () => {
        context.changeFormState("officeAddress", null);
        setAddressState(AddressLookupState.Search);
    };

    useEffect(() => {
        if (context.formState.officeAddressType === OfficeAddressKind.VirtualAddress) {
            context.changeFormState("officeAddress", VirtualOfficeAddress);
            context.changeFormState("countryOfRegistrationKind", CountryOfRegistrationEnum.EnglandAndWales);
        }
        if (context.formState.officeAddressType === OfficeAddressKind.NewAddress) {
            context.changeFormState("officeAddress", undefined);
        }
        if (context.formState.officeAddressType === OfficeAddressKind.ExistingAddress) {
            context.changeFormState("officeAddress", context.primaryStakeholder.address);
        }
    }, [context.formState.officeAddressType]);

    const disableOptions = () => context.formState.officeAddressType === OfficeAddressKind.VirtualAddress && virtualOfficeProducts.some(x => isInBasket(x.id));

    return (
        <div className="footerGap">
            <h3 className="text-header">Registered Office Address</h3>
            <FormLabel className="text-accent d-block mb-4">Your office address information will be submitted to Companies House
                and is required to complete the formation of your company.
            </FormLabel>
            <div>
                <RadioButton
                    disabled={virtualOfficeProducts.length === 0}
                    checked={context.formState.officeAddressType === OfficeAddressKind.VirtualAddress}
                    onChange={setVirtualAddress}
                    label="Use Virtual Office Address"
                    name="virtualAddress"
                    id="virtualAddress"
                    className="my-3"
                    subText="Your registered address will be available online if anyone is to search for your company.
                        For this reason, many directors choose to register their company at a virtual office address,
                        keeping your home address private if you trade from home."
                />
                {context.formState.officeAddressType === OfficeAddressKind.VirtualAddress && (
                    <div>
                        <div className="d-flex align-content-stretch flex-wrap">
                            {upsells.map(upsell => (<UpsellTile key={upsell.upsellId} upsell={upsell} productId={getUpsellProductIdIfInBasket(upsell.products)} />))}
                        </div>
                    </div>
                )}
                <RadioButton
                    disabled={disableOptions()}
                    checked={context.formState.officeAddressType === OfficeAddressKind.ExistingAddress}
                    onChange={setExistingAddress}
                    label="Use your Personal Address"
                    name="existingAddress"
                    id="existingAddress"
                    className="my-3"
                    subText="Your registered address can be your home address;
                        however, a registered office address gives you more privacy."
                />
                {context.formState.officeAddressType === OfficeAddressKind.ExistingAddress && (
                    <div className="ml-5">
                        <AddressLookUp
                            address={context.formState.officeAddress}
                            heading=""
                            hideButtons
                        />
                    </div>
                )}
                <RadioButton
                    checked={context.formState.officeAddressType === OfficeAddressKind.NewAddress}
                    disabled={disableOptions()}
                    onChange={setNewAddress}
                    label="Use Another Address"
                    name="newAddress"
                    id="newAddress"
                    className="my-3"
                    subText="You may rent an office or have a different address you want to use for your company."
                />
            </div>
            {context.formState.officeAddressType === OfficeAddressKind.NewAddress && (
                <div className="ml-5">
                    <AddressInput
                        address={context.formState.officeAddress ?? {} as Address}
                        addressState={addressState}
                        isValidAddress={isValidAddress}
                        onSaveSearchedAddress={onSaveSearchedAddress}
                        onEditSavedAddressClick={onEditSavedAddressClick}
                        onRemoveSavedAddressClick={onRemoveSavedAddressClick}
                        onManualAddressChange={onManualAddressChange}
                        onSwitchToManualAddressClick={onSwitchToManualAddressClick}
                        onSaveManualAddressClick={onSaveManualAddressClick}
                        onCancelManualAddressClick={OnCancelManualAddressClick}
                    />
                </div>)}
            {context.formState.officeAddressType === OfficeAddressKind.NewAddress
                && addressState === AddressLookupState.ReadOnly && isValidAddress
                && (
                    <div className="ml-5 mt-3">
                        <h5 className="text-header">What country is this address in?</h5>
                        {Object.keys(UkAddressCountry)
                            .map(countryValue => (
                                <div key={countryValue}>
                                    <RadioButton
                                        checked={(context.formState.officeAddress && context.formState.officeAddress.country === UkAddressCountry[countryValue]) as boolean}
                                        onChange={() => setAddressCountry(countryValue)}
                                        label={UkAddressCountry[countryValue]}
                                        name="addressCountry"
                                        id={`addressCountry${countryValue}`}
                                        className="my-3"
                                    />
                                </div>
                            ))}
                    </div>
                )}
        </div>
    );
};
