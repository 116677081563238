import React, { useEffect, useState } from "react";
import { Form } from "reactstrap";
import classnames from "classnames";

import api from "Utils/api";
import { FileUploader } from "Components/FileUploader";
import { encodeFileToBase64 } from "Utils/base64EncodeHelper";
import { useCompanyFormationsContext } from "../../Context";
import { FormLabel } from "../../Components/FormLabel";
import { CheckListModal } from "../../Components/CheckListModal";

import styles from "./styles.module.scss";

// THIS PAGE IS CURRENTLY SKIPPED AS PER REQUEST
// See useCompanyFormationsContext
export const KYCForm = () => {
    const [allowContinue, setAllowContinue] = useState(false);
    const stepperContext = useCompanyFormationsContext();
    const fileErrorMessage = "Something went wrong when trying to upload the file";
    const [isLoading, setIsLoading] = useState<{ proofOfId: boolean, proofOfAddress: boolean }>({
        proofOfId: false,
        proofOfAddress: false,
    });
    const [error, setError] = useState<{ proofOfId: string, proofOfAddress: string }>({
        proofOfId: "",
        proofOfAddress: "",
    });
    const [showIdInfo, setShowIdInfo] = useState(false);
    const [showAddressInfo, setShowAddressInfo] = useState(false);

    const isInvalid = () => isLoading.proofOfId || isLoading.proofOfAddress;
    const isContinue = () => stepperContext.formState.proofOfAddress && stepperContext.formState.proofOfId;

    useEffect(() => {
        stepperContext.setProceedLabel("Skip");
    }, []);

    useEffect(() => {
        if (isContinue()) {
            stepperContext.setProceedLabel("Continue");
        } else {
            stepperContext.setProceedLabel("Skip");
        }
    }, [isContinue]);

    useEffect(() => {
        const isNotValid = isInvalid();
        setAllowContinue(!isNotValid);
        stepperContext.setDisableContinueButton(isNotValid);
    }, [isInvalid]);

    const onFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if (allowContinue) {
            stepperContext.navigateToStep(stepperContext.currentStep.nextPage);
        }
    };

    const saveFile = async (key: any, file: any) => {
        setIsLoading(prevState => ({
            ...prevState,
            [key]: true,
        }));
        setError(prevState => ({
            ...prevState,
            [key]: "",
        }));
        encodeFileToBase64(file)
            .then((res) => {
                const b64data = res.currentTarget.result;
                const request = {
                    filename: file.name,
                    data: b64data,
                };
                api.post("company-formations/uploadIdentityDocument", request)
                    .then((result) => {
                        stepperContext.changeFormState(key, { ...result });
                        setIsLoading(prevState => ({
                            ...prevState,
                            [key]: false,
                        }));
                    })
                    .catch(() => {
                        setIsLoading(prevState => ({
                            ...prevState,
                            [key]: false,
                        }));
                        setError(prevState => ({
                            ...prevState,
                            [key]: fileErrorMessage,
                        }));
                    });
            });
    };

    const renderIdModal = () => (
        <CheckListModal
            title="Valid Address Proof"
            showModal={() => setShowIdInfo(true)}
            hideModal={() => setShowIdInfo(false)}
            items={
                ["Passport", "Full/Provisional Driving License", "Birth Certificate"]
            }
            showLogo
            targetId="valid-id-info"
            show={showIdInfo}
        />
    );

    const renderAddressModal = () => (
        <CheckListModal
            title="Valid Address Proof"
            showModal={() => setShowAddressInfo(true)}
            hideModal={() => setShowAddressInfo(false)}
            items={
                ["A valid UK driving license",
                    "Recent evidence of entitlement to a state- or local authority-funded benefit "
                    + "(including housing benefit, council tax benefit, tax credits, state pension, educational or other grant)",
                    "Instrument of a court appointment (such as a grant of probate)", "Current council tax demand letter or statement",
                    "HMRC-issued tax notification", "End of year tax deduction certificates",
                    "Current bank statements or credit/debit card statements", "Current utility bills"]
            }
            showLogo
            targetId="valid-address-info"
            show={showAddressInfo}
        />
    );

    return (
        <Form onSubmit={onFormSubmit}>
            <h3 className="text-header">Identification Documents</h3>
            <FormLabel className="text-accent d-block mb-4">Providing your documents now will speed up your company submission. If you
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                don't have them to hand, you can choose to do this step later.
            </FormLabel>
            <div className="mb-5">
                <div className="d-flex p-2 pt-3">
                    <h5 className="text-header">Proof of valid ID</h5>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                    <div
                        className={classnames(styles.infoButton, "p-0 m-0 ml-2 border-0 shadow-none")}
                        onClick={() => setShowIdInfo(true)}
                        id="valid-id-info"
                    >
                        <i className="fas fa-info-circle text-accent" />
                    </div>
                </div>
                <FileUploader
                    onChange={(file) => saveFile("proofOfId", file)}
                    filename={stepperContext.formState.proofOfId?.originalFileName || ""}
                    isLoading={isLoading.proofOfId}
                    testId="proofOfIdUpload"
                />
                {
                    error.proofOfId && <p className="text-danger">{error.proofOfId}</p>
                }
            </div>

            <div>
                <div className="d-flex p-2 pt-3">
                    <h5 className="text-header">Proof of valid Address</h5>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                    <div
                        className={classnames(styles.infoButton, "p-0 m-0 ml-2 border-0 shadow-none")}
                        onClick={() => setShowAddressInfo(true)}
                        id="valid-address-info"
                    >
                        <i className="fas fa-info-circle text-accent" />
                    </div>
                </div>
                <FileUploader
                    onChange={(file) => saveFile("proofOfAddress", file)}
                    filename={stepperContext.formState.proofOfAddress?.originalFileName || ""}
                    isLoading={isLoading.proofOfAddress}
                    testId="validAddressProofUpload"
                />
                {
                    error.proofOfAddress && <p className="text-danger">{error.proofOfAddress}</p>
                }
                {renderIdModal()}
                {renderAddressModal()}
            </div>
        </Form>
    );
};
