import React, { useEffect } from "react";
import { Form } from "reactstrap";

import { useCompanyFormationsContext } from "../../Context";
import { UpsellTile } from "../../Components/UpsellTile";
import { useUpsell } from "../../Components/UpsellTile/useUpsell";

export const CelebrateAchievementForm = () => {
    const context = useCompanyFormationsContext();
    const { getUpsellsByPage, getUpsellProductIdIfInBasket } = useUpsell();
    const upsells = getUpsellsByPage();

    useEffect(() => {
        context.setDisableContinueButton(false);
        context.setProceedLabel("Continue");
    }, []);

    return (
        <>
            <Form className="footerGap">
                <h3 className="text-primary">Celebrate your achievement!</h3>
                <h6 className="mt-4">Starting your own business is an amazing achievement,
                    and now you have made the first concrete step to turn your ideas into reality.
                </h6>
                <h6 className="mt-3">A milestone worth remembering: a framed certificate to proudly display
                    and enjoy the moment {context.formState.companyName} began...
                </h6>
                <div>
                    <div className="d-flex align-content-stretch flex-wrap">
                        {upsells.map(upsell => (<UpsellTile key={upsell.upsellId} upsell={upsell} productId={getUpsellProductIdIfInBasket(upsell.products)} />))}
                    </div>
                </div>
            </Form>
        </>
    );
};
