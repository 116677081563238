import moment from "moment/moment";

const maxBirthDate = moment()
    .subtract(16, "years")
    .toDate();
const minBirthDate = moment()
    .subtract(80, "years")
    .toDate();

export { minBirthDate, maxBirthDate };
