import React, { useCallback, useEffect, useState } from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Visible } from "../Visible";
import TextBox from "../Textbox";
import { AddressEvents, isValidAddress } from "../AddressLookup/address.instance";

import styles from "./styles.module.scss";

// eslint-disable-next-line max-len
const ManualAddress = ({ initialAddress, goToSearch, onSave, reset, addressEvents, hideButtons, isModal, onCancel, disableSaveIfInvalid, onCanSaveChanged, onManualAddressChange }) => {
    const [address, setAddress] = useState({ ...initialAddress });
    const [canSave, setCanSave] = useState(false);

    const checkValidAddress = useCallback((newAddress) => {
        const isValid = isValidAddress(newAddress);
        setCanSave(disableSaveIfInvalid ? isValid : true);
        onManualAddressChange(newAddress, isValid);
        if (addressEvents && addressEvents.validManualAddressChange) {
            addressEvents.validManualAddressChange.next(isValid);
        }
    }, [addressEvents, disableSaveIfInvalid]);

    useEffect(() => {
        if (onCanSaveChanged) {
            onCanSaveChanged(canSave);
        }
    }, [onCanSaveChanged, canSave]);

    const onSetAddress = field => (e) => {
        const newAddress = { ...address, [field]: e.target.value };
        setAddress(newAddress);
        checkValidAddress(newAddress);
        reset();
    };

    useEffect(() => {
        checkValidAddress(address);
    }, [checkValidAddress, address, address.addressLine1, address.city, address.addressLine2, address.postCode]);

    const onSaveClick = () => {
        onSave(address);
    };

    const onCancelClick = () => {
        if (onCancel) {
            onCancel();
        }
    };

    if (addressEvents?.saveEvent) {
        addressEvents.saveEvent.subscribe(() => {
            onSaveClick();
        });
    }

    return (
        <div className="flex-grow-1">
            <TextBox
                label="Premise"
                onChange={onSetAddress("premise")}
                value={address.premise}
                data-testid="premiseInput"
            />

            <TextBox
                label="Address Line 1"
                onChange={onSetAddress("addressLine1")}
                value={address.addressLine1}
                data-testid="addressLine1Input"
            />

            <TextBox
                label="Address Line 2"
                onChange={onSetAddress("addressLine2")}
                value={address.addressLine2}
                data-testid="addressLine2Input"
            />

            <TextBox
                label="City"
                onChange={onSetAddress("city")}
                value={address.city}
                data-testid="cityInput"
            />

            <TextBox
                label="Post Code"
                onChange={onSetAddress("postCode")}
                value={address.postCode}
                data-testid="postCodeInput"
            />

            {!hideButtons
            && (
                isModal
                    ? (
                        <div className={styles.buttons}>
                            <Button color="primary" onClick={goToSearch}>I&apos;d like to search for my address using
                                post code instead
                            </Button>
                            <span className="filler" />
                            <Button className="mr-2" onClick={onCancelClick} color="secondary">Cancel</Button>
                            <Button onClick={onSaveClick} disabled={!canSave} color="primary">Save</Button>
                        </div>
                    )
                    : (
                        <>
                            <div className="mb-2 postCodeToggleButton">
                                <Button color="primary" onClick={goToSearch}>
                                    <Visible below="Lg">
                                        <>Search for my address using post code instead</>
                                    </Visible>
                                    <Visible forAndAbove="Lg">
                                        <>I&apos;d like to search for my address using post code instead</>
                                    </Visible>
                                </Button>
                            </div>
                            <div className={classnames(styles.modalButtons, "manualAddressButtons")}>
                                <Button
                                    className="mb-2"
                                    disabled={!canSave}
                                    onClick={onSaveClick}
                                    color="primary"
                                >Save
                                </Button>
                                <Button onClick={onCancelClick} color="secondary">Cancel</Button>
                            </div>
                        </>
                    )
            )}
        </div>
    );
};

ManualAddress.propTypes = {
    onSave: PropTypes.func.isRequired,
    goToSearch: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    initialAddress: PropTypes.shape({
        addressLine1: PropTypes.string.isRequired,
        addressLine2: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        postCode: PropTypes.string.isRequired,
    }),
    addressEvents: PropTypes.instanceOf(AddressEvents),
    hideButtons: PropTypes.bool,
    isModal: PropTypes.bool,
    onCancel: PropTypes.func,
    disableSaveIfInvalid: PropTypes.bool,
    onCanSaveChanged: PropTypes.func,
    onManualAddressChange: PropTypes.func,
};

ManualAddress.defaultProps = {
    hideButtons: false,
    onManualAddressChange: () => {},
};

export { ManualAddress };
