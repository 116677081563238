import { Address } from "../Types/Address";

export const validateEmail = (email: string | undefined) => {
    // eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email)
        .toLowerCase());
};

export const validateAddress = (address?: Address): boolean => !!address?.addressLine1 && !!address.city && !!address.postCode;

export const isValidNumber = (answer: string): boolean => {
    if (answer.length < 9 || answer.length > 13) {
        return false;
    }

    // 0 or 1 '+'
    // 1 or more numbers
    const regex = new RegExp("^[+]?[0-9]{9,12}");
    const isMatch = regex.test(answer);

    return (isMatch);
};
