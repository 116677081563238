import React from "react";
import PropTypes from "prop-types";

import Checkbox from "../Checkbox";

const TermsAndConditions = ({ cdnUrl, checked, businessName, onToggle, documentLocation, showPrivacy }) => {
    const business = businessName || "the Business Support Club";

    return (
        <div className="d-flex align-items-center">
            <Checkbox
                testId="acceptTermsCheckbox"
                id={`terms-${businessName}`}
                checked={checked}
                onChange={onToggle}
                className="d-inline-block mr-0"
            />
            <strong>
                I agree to the&nbsp;
                <a href={`${cdnUrl || window.cdnUrl}${documentLocation}`} rel="noopener noreferrer" className="text-bscPrimary" target="_blank">
                    Terms and Conditions
                </a>
                {showPrivacy && (
                    <span>
                        &nbsp;and&nbsp;
                        <a href={`${cdnUrl || window.cdnUrl}/misc/Business-Support-Club-Privacy-Policy.pdf`} target="_blank" rel="noopener noreferrer" className="text-bscPrimary">
                            Privacy Policy
                        </a>
                    </span>
                )}
                &nbsp;of {business}.
            </strong>
        </div>
    );
};

TermsAndConditions.propTypes = {
    businessName: PropTypes.string,
    documentLocation: PropTypes.string,
    showPrivacy: PropTypes.bool.isRequired,
    checked: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
    cdnUrl: PropTypes.string,
};

TermsAndConditions.defaultProps = {
    documentLocation: "misc/Business-Support-Club-Terms-And-Conditions.pdf",
};

export { TermsAndConditions };
