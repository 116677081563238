import React, { useEffect, useState } from "react";

import { checkout } from "../../Apis/BasketApi";
import { useCompanyFormationsContext } from "../../Context";
import { Address, UkAddressCountry } from "../../Types/Address";
import { CountryOfRegistrationEnum } from "../OfficeAddressForm/CountryOfRegistrationEnum";
import { PersonalInformationSummary } from "../../Components/PersonalInformationSummary";
import { CompanyInformationSummary } from "../../Components/CompanyInformationSummary";
import Checkbox from "../../Components/Checkbox";
import { SignUpModal } from "../../Modals/SignUpModal";
import { AdditionalPurchasesSummary } from "../../Components/AdditionalPurchasesSummary";

export const SummaryForm: React.FC = () => {
    const context = useCompanyFormationsContext();
    const [hasError, setHasError] = useState(false);
    const termAndConditionLink = `${context.config?.configuration?.bscCdnUrl}misc/TermsAndConditions-${context.config?.configuration?.theme}.pdf`;
    const formatAddressForTransmission = (address: Address | undefined): string | null => (address?.addressLine1 ? JSON.stringify(address) : null);

    const toggleAcceptedTerms = () => {
        context.changeFormState("hasAcceptedTerms", !context.formState.hasAcceptedTerms);
    };

    const getEnumName = (value: any, enumType: any) => {
        if (!value) {
            return null;
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const name in enumType) {
            if (enumType[name] === value) {
                return name;
            }
        }
        return null;
    };

    const formatFormStateForTransmission = () => ({
        ...context.formState,
        ...context.primaryStakeholder,
        address: formatAddressForTransmission(context.primaryStakeholder.address as Address),
        addressCountry: getEnumName(context.primaryStakeholder.address?.country, UkAddressCountry),
        officeAddress: formatAddressForTransmission(context.formState.officeAddress),
        officeAddressCountry: getEnumName(context.formState.officeAddress?.country, UkAddressCountry),
        countryOfRegistrationKind: getEnumName(context.formState.countryOfRegistrationKind, CountryOfRegistrationEnum),
        proofOfId: context.formState.proofOfId?.uploadedFileName,
        proofOfAddress: context.formState.proofOfAddress?.uploadedFileName,
        previousAddress: formatAddressForTransmission(context.formState.previousAddress),
        previousAddressCountry: getEnumName(context.formState.previousAddress?.country, UkAddressCountry),
        stakeholders: context.formState.stakeholders
            .filter(x => !x.isPrimary)
            .map(x => ({
                ...x,
                address: formatAddressForTransmission(x.address),
                addressCountry: getEnumName(context.primaryStakeholder.address?.country, UkAddressCountry),
            })),
    });

    const AgreeToTermsCheckbox = () => (
        <div className="pt-4">
            <div className="tw-flex">
                <Checkbox
                    id="accepted"
                    disabled={context.isLoading}
                    checked={!!context.formState.hasAcceptedTerms}
                    onChange={toggleAcceptedTerms}
                    label="I accept these"
                />
                <div className="tw-flex !tw--ml-7 tw-mt-0.5">
                    <a
                        href={termAndConditionLink}
                        rel="noopener noreferrer"
                        className="text-underline text-bscPrimary"
                        target="_blank"
                    >
                        company registration terms
                    </a>
                </div>
            </div>
        </div>
    );

    const saveCompanyFormation = () => {
        context.setDisableContinueButton(true);
        context.setIsLoading(true);
        const companyFormAnswer = formatFormStateForTransmission();
        checkout({
            companyFormationsProductId: context.config?.configuration.productId ?? "",
            companyFormationFieldAnswers: companyFormAnswer,
            upsellProductIds: context.formState.bscUpsellBasketIds,
        })
            .catch(() => {
                setHasError(true);
            });

        // Moved these two state changes to outside of the finally block
        // As it makes almost no difference and the finally block causes
        // "act" errors in JEST https://github.com/testing-library/react-testing-library/issues/1051
        context.setIsLoading(false);
        context.setDisableContinueButton(!context.formState.hasAcceptedTerms);
    };

    useEffect(() => {
        saveCompanyFormation();
    }, []);

    useEffect(() => {
        context.setDisableContinueButton(!context.formState.hasAcceptedTerms);
    }, [context.formState.hasAcceptedTerms]);

    return (
        <div className="d-flex flex-column overflow-auto footerGapTC">
            <h3 className="text-header mb-3">
                Summary
            </h3>
            <PersonalInformationSummary />
            <CompanyInformationSummary />
            {context.formState.bscUpsellBasketIds.length > 0 && (<AdditionalPurchasesSummary upsellDeleted={saveCompanyFormation} />)}
            {hasError && <div className="alert alert-danger mt-4 mb-0">Something went wrong with your submission.</div>}
            <AgreeToTermsCheckbox />
            <SignUpModal
                bscUrl={context?.config?.configuration?.bscUrl!}
                cdnUrl={context?.config?.configuration?.bscCdnUrl!}
            />
        </div>
    );
};
