import React, { useCallback, useEffect, useState } from "react";

import { ProgressBarProps } from "./ProgressBarProps";
import styles from "./styles.module.scss";

export const ProgressBar: React.FC<ProgressBarProps> = ({ minimumValue = 0, maximumValue = 1, value }: ProgressBarProps) => {
    const calculatePercentage = useCallback(() => ((value - minimumValue) / (maximumValue - minimumValue)) * 100,
        [value, minimumValue, maximumValue]);

    const [percentage, setPercentage] = useState(calculatePercentage());

    useEffect(() => {
        setPercentage(calculatePercentage());
    }, [setPercentage, calculatePercentage]);

    return (
        <div className={`${styles.progressBar} bg-secondary`}>
            <div className={`${styles.progressBarProgress} bg-progress`} style={{ width: `${percentage}%` }} />
        </div>
    );
};
