import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import classnames from "classnames";

import { acceptedFileTypes } from "../constants";

import styles from "./styles.module.scss";

interface Props {
    onAdd(acceptedFile): void;
    children: React.ReactNode;
    accept?: string;
    preDropHook?(acceptedFiles): boolean;
    dragActiveHook?(isDragActive): void;
    noClick?: boolean;
    disabled?: boolean;
    className?: string;
}

const DropZoneArea = (
    {
        onAdd,
        children,
        dragActiveHook,
        accept = acceptedFileTypes.documents,
        noClick,
        className,
        disabled = false,
    }: Props,
) => {
    const [showValidation, setShowValidation] = useState(false);

    const onDropRejected = () => {
        setShowValidation(true);
    };

    const onDropAccepted = (files) => {
        onAdd(files[0]);
        setShowValidation(false);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDropRejected,
        onDropAccepted,
        multiple: false,
        maxSize: 8000000,
        accept,
        noClick,
    });

    useEffect(() => {
        if (dragActiveHook) {
            dragActiveHook(isDragActive);
        }
    }, [isDragActive]);

    return (
        <>
            {
                showValidation
                && <div className="alert alert-warning">Files must be less than 8MB, not a .exe or .dll.</div>
            }
            <div
                {...getRootProps()}
                tabIndex={-1}
                className={classnames(className && className, !className && styles.dropzone)}
            >
                <input {...getInputProps()} data-testid="drop-zone-input" disabled={disabled} />
                {children}
            </div>
        </>
    );
};

export { DropZoneArea };
