import { Button } from "reactstrap";
import React from "react";
import classname from "classnames";

interface Props {
    onViewDetails: () => void,
    onRemove: () => void,
    className?: string
    buttonClassName?: string,
    dataTestId?: string,
}

const StakeholderActionButtons = ({
    onViewDetails,
    onRemove,
    className,
    buttonClassName,
    dataTestId,
}: Props) => (
    <div
        className={classname("tw-flex tw-flex-row tw-space-x-3", className)}
        data-testid={dataTestId}
    >
        <Button
            className={classname("font-weight-bold tw-h-10 tw-mr-2 tw-underline text-primary", buttonClassName)}
            color="transparent"
            size="sm"
            onClick={onViewDetails}
        >
            Amend Details
        </Button>
        <Button
            color="transparent"
            size="sm"
            className={classname("text-danger font-weight-bold tw-h-10 outline-none tw-underline", buttonClassName)}
            onClick={onRemove}
        >
            Remove
        </Button>
    </div>
);

export { StakeholderActionButtons };
