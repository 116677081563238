import api from "Utils/api";

export interface LocalConfig {
    domainUrl: string;
    termsAndConditionsLink: string;
    privacyPolicyLink: string;
    maxNumberStakeholders: number,
}

export interface CompanyFormationsConfigType extends LocalConfig {
    saveToLocalLocalStorage?: boolean;
    bscUrl: string;
    bscCdnUrl: string;
    raygunApiKey: string;
    gtmKey: string,
    hotjarId: number,
    hotjarSnippetVersion: number;
    productId: string;
    theme: string;
    termAndConditionLink: string;
}

export interface SicCodeType {
    id: number;
    description: string;
}

export interface UpsellPageConfigType {
    pageUri: string;
    upsells: UpsellItemType[];
}

export interface UpsellItemType {
    products: UpsellProductType[];
    upsellId: string;
    upsellName: string;
    description;
    skipRegisteredOfficeStep: boolean;
    upsellContainsVirtualAddress: boolean;
    isCashPlusProduct: boolean;
    oneUpsellOnly: boolean;
    isOnlyImage: boolean;
    imageRelativePath?: string;
}

export interface UpsellProductType {
    id: string;
    name: string;
    description: string;
    price: number;
    priceFrequency: string;
    images: string[];
    moreInfo: string;
    productData;
    isCashPlusProduct: boolean;
}

export interface CompanyFormationsConfigResponse {
    sicCodes: SicCodeType[];
    configuration: CompanyFormationsConfigType;
    upsells: UpsellPageConfigType[];
}

const getConfiguration = (forUri: string): Promise<CompanyFormationsConfigResponse> => api.post("company-formations/configuration", { uri: forUri });

export {
    getConfiguration,
};
