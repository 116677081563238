import { Form } from "reactstrap";
import React, { useEffect } from "react";
import Select from "react-select";

import { useCompanyFormationsContext } from "../../Context";
import { UpsellTile } from "../../Components/UpsellTile";
import { useUpsell } from "../../Components/UpsellTile/useUpsell";
import { FormLabel } from "../../Components/FormLabel";

import { sicSections, sicRanges } from "./sicSections";

export const BusinessCategoryForm = () => {
    const context = useCompanyFormationsContext();
    const { getUpsellsByPage, getUpsellProductIdIfInBasket } = useUpsell();
    const upsells = getUpsellsByPage();
    const sicSectionOptions = sicSections.map((x) => ({ label: x.label, value: x.value }));
    const sicOptions = context.config?.sicCodes.map((x) => ({ label: `${x.description} (${x.id})`, value: x.id }));
    const sicOptionsFiltered = sicOptions?.filter(x => (context.formState.sicSection ? x.value >= sicRanges.filter(y => y.value === context.formState.sicSection)[0].startRange
        && x.value < sicRanges.filter(y => y.value === context.formState.sicSection)[0].endRange : true));

    const onChange = (event) => context.changeFormState("sicCode", event.value);

    const onChangeSection = (event) => {
        context.changeFormState("sicSection", event.value);
        context.changeFormState("sicCode", null);
    };

    useEffect(() => {
        context.setProceedLabel("Continue");
    }, []);

    useEffect(() => {
        context.setDisableContinueButton(!context.formState.sicCode);
    }, [context.formState.sicCode]);

    return (
        <>
            <h3 className="text-header">Business Category</h3>
            <FormLabel className="text-accent d-block mb-4">Search for or select the category that best describes your business.
                This description must be as accurate as possible.
            </FormLabel>
            <Form className="footerGap">
                <FormLabel htmlFor="businessCategory">Business category</FormLabel>
                <Select
                    placeholder="Search by text..."
                    options={sicSectionOptions}
                    onChange={onChangeSection}
                    className="my-3"
                    value={context.formState.sicSection
                        ? {
                            label: sicSections.find(x => x.value === context.formState.sicSection)?.label,
                            value: context.formState.sicSection,
                        }
                        : null}
                    inputId="businessCategory"
                />
                <FormLabel htmlFor="businessType">Business type</FormLabel>
                <Select
                    placeholder="Search by text or SIC code..."
                    options={sicOptionsFiltered}
                    onChange={onChange}
                    className="my-3"
                    value={context.formState.sicCode
                        ? {
                            label: `${context.config?.sicCodes.find(x => x.id === context.formState.sicCode)?.description} (${context.formState.sicCode})`,
                            value: context.formState.sicCode,
                        }
                        : null}
                    inputId="businessType"
                />
                <div>
                    <div className="d-flex align-content-stretch flex-wrap">
                        {upsells.map(upsell => (<UpsellTile key={upsell.upsellId} upsell={upsell} productId={getUpsellProductIdIfInBasket(upsell.products)} />))}
                    </div>
                </div>
            </Form>
        </>
    );
};
