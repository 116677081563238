import api from "Utils/api";

interface SlimSignUpType {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
    termsAgreed: boolean;

}

const verifyEmail = (token: string | null) => api.post("account/verifyemail", { token });

const resendVerificationEmail = (token: string | null) => api.post("account/resendVerifyEmail", { token });

const slimSignUp = (request: SlimSignUpType) => api.post("account/bsc-partners/register", request);

export {
    verifyEmail,
    resendVerificationEmail,
    slimSignUp,
};
