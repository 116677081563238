import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, ModalBody, ModalHeader } from "reactstrap";
import Select from "react-select";
import moment from "moment";

import { Modal } from "../Modal";
import { FormLabel } from "../../Components/FormLabel";
import Textbox from "../../Components/Textbox";
import { DayMonthYearField } from "../../Components/DayMonthYearField";
import { countries } from "../../Pages/PersonalInformationForm/countries";
import { nationalities } from "../../Pages/PersonalInformationForm/nationalities";
import { defaultStakeholder, Stakeholder, StakeHolderType, titles } from "../../Types/PersonalInformation";
import CloseButton from "../../Components/CloseButton";
import { maxBirthDate, minBirthDate } from "../../Utils/dateHelper";
import { useAddressHook } from "../../Hooks/useAddressHook";
import { AddressInput } from "../../Components/AddressInput";
import { Address, UkAddressCountry } from "../../Types/Address";
import { AddressLookupState } from "../../Components/AddressLookup/address.instance";
import RadioButton from "../../Components/RadioButton";
import { enumDisplay } from "../../Utils/formatStringHelper";
import { isValidNumber } from "../../Utils/validator";

export interface Props {
    onFormSubmit: (event: Stakeholder) => void,
    isOpen: boolean,
    toggleModal: () => void,
    stakeholder?: Stakeholder, // This will be used to determine whether we are in create or edit mode
    personType: StakeHolderType,
    title: string,
    cancelButtonText?: string,
    submitButtonText?: string,
}

const PersonalInformationModal = ({
    onFormSubmit,
    isOpen,
    toggleModal,
    stakeholder,
    personType,
    title,
    cancelButtonText = "Cancel",
    submitButtonText = "Submit",
}: Props) => {
    const [dobValid, setDobValid] = useState(true);
    const [isFormStateValid, setIsFormStateValid] = useState(false);
    const [formState, setFormState] = useState<Stakeholder>(stakeholder ?? defaultStakeholder(personType));

    const {
        addressState,
        onSaveSearchedAddress,
        onSwitchToManualAddressClick,
        onManualAddressChange,
        onSaveManualAddressClick,
        onCancelManualAddressClick,
        onEditSavedAddressClick,
        onRemoveSavedAddressClick,
        isValidAddress,
    } = useAddressHook(stakeholder?.address ?? defaultStakeholder("Director").address);

    const isFormStateInvalid = (): boolean => !!formState
        && (
            !formState.title || !formState.firstName || !formState.lastName
            || (formState.middleName && formState.middleName.length < 2)
            || !dobValid
            || !formState.nationality || !formState.countryOfResidence
            || !isValidAddress || !formState.address?.country
            || ((personType === "Director" || personType === "PersonSignificantControl") && !isValidNumber(formState.mobileNumber || ""))
        );

    useEffect(() => {
        if (stakeholder) setFormState(stakeholder);
    }, [stakeholder]);

    useEffect(() => {
        setIsFormStateValid(!isFormStateInvalid());
    }, [isFormStateInvalid]);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormState(prev => ({
            ...prev,
            [event.target.name]: event.target.value,
        }));
    };

    const onSaveSearchedAddressOverride = (newAddress: Address) => {
        setFormState(prev => ({
            ...prev,
            address: newAddress,
        }));
        onSaveSearchedAddress();
    };

    const onManualAddressChangeOverride = (newAddress: Address, isValid: boolean) => {
        onManualAddressChange(isValid);
        setFormState(prev => ({
            ...prev,
            address: newAddress,
        }));
    };

    const onRemoveSavedAddressClickOverride = () => {
        setFormState(prev => ({
            ...prev,
            address: {} as Address,
        }));
        onRemoveSavedAddressClick();
    };

    const onDateChanged = (valid, event) => {
        if (valid) {
            setFormState(prev => ({
                ...prev,
                dateOfBirth: moment(event)
                    .format("YYYY-MM-DD HH:mm:ss"),
            }));
        }

        setDobValid(valid);
    };

    const onFormSubmitOverride = (event: React.FormEvent) => {
        event.preventDefault();
        onFormSubmit(formState);
    };

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggleModal}
            size="lg"
        >
            <ModalHeader className="tw-relative">
                {title}
                <CloseButton onClick={toggleModal} className="!tw-outline-0" />
            </ModalHeader>
            <ModalBody className="!tw-p-10">
                <h6>Legal Name</h6>
                <FormLabel className="text-accent d-block mb-2">
                    Please enter your legal name as it appears on your passport. The information entered here will make up
                    the {enumDisplay(personType)}&apos;s details.
                </FormLabel>
                <Form onSubmit={onFormSubmitOverride}>
                    <FormGroup>
                        <FormLabel>Title</FormLabel>
                        <Select
                            placeholder="Select your title"
                            options={titles
                                .map((x) => ({
                                    label: x,
                                    value: x,
                                }))}
                            onChange={(event) => onChange(({
                                target: {
                                    name: "title",
                                    value: event.label,
                                },
                            } as React.ChangeEvent<HTMLInputElement>))}
                            className="my-2"
                            value={formState.title ? {
                                label: formState.title,
                                value: formState.title,
                            } : null}
                            inputId="titleSelect"
                        />
                    </FormGroup>
                    <Textbox
                        isRequired
                        label={<FormLabel>First name</FormLabel>}
                        value={formState.firstName}
                        name="firstName"
                        onChange={onChange}
                        placeholder="Enter your first name"
                        data-testid="firstNameInput"
                    />
                    <Textbox
                        label={<FormLabel optional>Middle name</FormLabel>}
                        value={formState.middleName}
                        name="middleName"
                        onChange={onChange}
                        placeholder="Enter your middle name"
                        data-testid="middleNameInput"
                        regex="^.{2,}$"
                        isInvalidError="Please enter your full middle name"
                    />
                    <Textbox
                        isRequired
                        label={<FormLabel>Last name</FormLabel>}
                        value={formState.lastName}
                        name="lastName"
                        onChange={onChange}
                        placeholder="Enter your last name"
                        data-testid="lastNameInput"
                    />
                    {(personType === "Director" || personType === "PersonSignificantControl") && (
                        <Textbox
                            className="mt-4"
                            label={<FormLabel>Mobile Number</FormLabel>}
                            value={formState.mobileNumber}
                            onChange={onChange}
                            name="mobileNumber"
                            error={formState.mobileNumber && !isValidNumber(formState.mobileNumber) ? "Please enter a valid mobile number" : ""}
                            placeholder="Enter your mobile number"
                            data-testid="mobileNumberInput"
                        />
                    )}
                    <h5 className="text-header mt-4">Date of Birth</h5>
                    <FormLabel className="text-accent d-block">Your date of birth is a requirement to complete your director information.</FormLabel>
                    <FormGroup className="mt-2">
                        <DayMonthYearField
                            max={maxBirthDate}
                            min={minBirthDate}
                            changeDate={onDateChanged}
                            currentDate={formState.dateOfBirth}
                            dataTestId="date-of-birth"
                        />
                    </FormGroup>
                    <h5 className="text-header mb-3">Citizenship</h5>
                    <FormLabel htmlFor="countryOfResidenceInput">Country of residence</FormLabel>
                    <FormLabel className="text-accent d-block">This is the country where you live and reside. It may not be the same as your nationality.</FormLabel>
                    <Select
                        placeholder="Search..."
                        options={countries.map((x) => ({
                            label: x,
                            value: x,
                        }))}
                        name="countryOfResidence"
                        onChange={(event) => onChange({
                            target: {
                                name: "countryOfResidence",
                                value: event.label,
                            },
                        } as React.ChangeEvent<HTMLInputElement>)}
                        className="my-3"
                        value={formState.countryOfResidence ? {
                            label: formState.countryOfResidence,
                            value: formState.countryOfResidence,
                        } : null}
                        inputId="countryOfResidenceInput"
                    />
                    <FormLabel htmlFor="nationalityInput">Nationality</FormLabel>
                    <FormLabel
                        className="text-accent d-block"
                    >
                        Search for your nationality. This is data required by Companies House as part of the formation process.
                    </FormLabel>
                    <Select
                        placeholder="Search..."
                        options={nationalities.map((x) => ({
                            label: x,
                            value: x,
                        }))}
                        onChange={(event) => onChange({
                            target: {
                                name: "nationality",
                                value: event.label,
                            },
                        } as React.ChangeEvent<HTMLInputElement>)}
                        className="my-3"
                        value={formState.nationality ? {
                            label: formState.nationality,
                            value: formState.nationality,
                        } : null}
                        inputId="nationalityInput"
                    />
                    <h5 className="text-header mt-4">Personal Address</h5>
                    <FormLabel className="text-accent d-block mb-4">Your personal address will be submitted to Companies House and is required to complete the formation
                        of your company.
                    </FormLabel>
                    <AddressInput
                        address={formState?.address ?? {} as Address}
                        addressState={addressState}
                        isValidAddress={isValidAddress}
                        onSaveSearchedAddress={onSaveSearchedAddressOverride}
                        onEditSavedAddressClick={onEditSavedAddressClick}
                        onRemoveSavedAddressClick={onRemoveSavedAddressClickOverride}
                        onManualAddressChange={onManualAddressChangeOverride}
                        onSwitchToManualAddressClick={onSwitchToManualAddressClick}
                        onSaveManualAddressClick={onSaveManualAddressClick}
                        onCancelManualAddressClick={onCancelManualAddressClick}
                    />
                    {addressState === AddressLookupState.ReadOnly && isValidAddress
                        && (
                            <div className="mt-3">
                                <h5 className="text-header">What country is this address in?</h5>
                                {Object.keys(UkAddressCountry)
                                    .map(countryValue => (
                                        <div key={`addressCountry${countryValue}`}>
                                            <RadioButton
                                                checked={(formState?.address && formState?.address.country === UkAddressCountry[countryValue]) as boolean}
                                                onChange={() => {
                                                    setFormState(prev => ({
                                                        ...prev,
                                                        address: {
                                                            ...prev.address,
                                                            country: UkAddressCountry[countryValue],
                                                        },
                                                    }));
                                                }}
                                                label={UkAddressCountry[countryValue]}
                                                name="addressCountry"
                                                id={`addressCountry${countryValue}`}
                                                className="my-3"
                                            />
                                        </div>
                                    ))}
                            </div>
                        )}
                    <div className="tw-flex tw-flex-row tw-justify-center tw-space-x-2 tw-mt-5">
                        <Button
                            color="secondary"
                            className="font-weight-bold tw-w-40"
                            onClick={toggleModal}
                            type="button"
                        >
                            {cancelButtonText}
                        </Button>
                        <Button
                            color="primary"
                            className="font-weight-bold tw-w-40"
                            disabled={!isFormStateValid}
                            type="submit"
                        >
                            {submitButtonText}
                        </Button>
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    );
};

export { PersonalInformationModal };
