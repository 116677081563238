export const uppercaseFirstLetter = (value: string): string => value.charAt(0)
    .toUpperCase() + value.slice(1);

export const substringFromLastIndexOf = (value: string, splitOn: string): string => value.substring(value ? value.lastIndexOf(splitOn) + 1 : 0);

export const toCurrency = (value: number | string): string => (parseFloat(Number(value)
    .toString())
    .toFixed(2));

export const pascalCaseToTitleCase = (value: string) => value.replace(/([A-Z])/g, " $1");

export const enumDisplay = (str: string) => {
    if (!str) return "";

    const rex = /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g;
    const spacedString = str.replace(rex, "$1$4 $2$3$5");
    return spacedString.charAt(0)
        .toUpperCase() + spacedString.slice(1); // Uppercase first letter
};
