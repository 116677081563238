export const encodeFileToBase64 = file => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = resolve;
    reader.readAsDataURL(file);
});

export const encodeFilesToBase64 = (files) => {
    const getBase64 = (file) => {
        const reader = new FileReader();
        return new Promise((resolve) => {
            reader.onload = (ev) => {
                resolve(ev.target.result);
            };
            reader.readAsDataURL(file);
        });
    };

    const promises = files.map(file => getBase64(file));
    return Promise.all(promises);
};

export const encodeFilesToBase64WithFileName = (files) => {
    const getBase64 = (file) => {
        const reader = new FileReader();
        return new Promise((resolve) => {
            reader.onload = (ev) => {
                resolve({ name: file.name, data: ev.target.result });
            };
            reader.readAsDataURL(file);
        });
    };

    const promises = files.map(file => getBase64(file));
    return Promise.all(promises);
};
