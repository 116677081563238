import { Address, UkAddressCountry } from "../../Types/Address";

export const VirtualOfficeAddress: Address = {
    premise: "Bartle House",
    addressLine1: "Oxford Court",
    addressLine2: "",
    city: "Manchester",
    postCode: "M2 3WQ",
    country: UkAddressCountry.England,
};
