import React from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";

export interface FormLabelProps {
    children: React.ReactNode;
    optional?: boolean;
    className?: string;
    htmlFor?: string;
}

export const FormLabel = ({ children, optional = false, className, htmlFor }: FormLabelProps) => (
    <label htmlFor={htmlFor} className={classNames(styles.formLabel, "font-weight-bold mb-0", className)}>
        {children}
        {optional && <span className="text-accent">&nbsp;(optional)</span>}
    </label>
);
