import React from "react";
import classNames from "classnames";

import SpinnerSvg from "../../Assets/Images/company-formations-spinner.svg";

import styles from "./styles.module.scss";

export interface SpinnerProps {
    size?: number;
    className?: string;
    testId?: string;
}

export const Spinner = ({ size = 36, className, testId = "spinner" }: SpinnerProps) => (
    <div className={classNames(styles.spin, "spinner", className)} style={{ height: `${size}px`, width: `${size}px` }} data-testid={testId}>
        <SpinnerSvg />
    </div>
);
