import React, { ReactNode, useState } from "react";
import { useHistory } from "react-router-dom";
import classnames from "classnames";

import { StepRoutes } from "Context/StepperState";
import { ConfirmDeleteBasketItemModal } from "../ConfirmDeleteBasketItemModal";

import styles from "./styles.module.scss";

export interface ReviewSummaryRowProps {
    title: string;
    description?: string;
    target: StepRoutes;
    children?: ReactNode;
    isAddress?: boolean;
    canDelete?: boolean;
    deleteItem?: () => void;
    scrollBottom?: boolean;
}

const ReviewSummaryRow: React.FC<ReviewSummaryRowProps> = ({
    title,
    description,
    target,
    children,
    isAddress = false,
    canDelete = false,
    deleteItem,
    scrollBottom = false,
}: ReviewSummaryRowProps) => {
    const history = useHistory();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const confirmedDeleteUpsell = () => {
        setShowDeleteModal(false);
        if (deleteItem) {
            deleteItem();
        }
    };
    return (
        <div className={styles.summaryContainer}>
            <span className={styles.title}>{title}</span>
            <span
                className={classnames(styles.description, isAddress && styles.address)}
                data-testid="description"
            >
                {description && description}
                {children && children}
            </span>
            <span>
                <button className={styles.editButton} type="button" onClick={() => history.push(target, { scrollBottom })}>
                    <i className="fas fa-edit text-accent" />
                </button>
                {canDelete && (
                    <button className={styles.deleteButton} type="button" onClick={() => setShowDeleteModal(true)}>
                        <i className="fas fa-trash-alt text-accent" />
                    </button>
                )}
            </span>
            {showDeleteModal && (
                <ConfirmDeleteBasketItemModal
                    key={title}
                    onClose={() => setShowDeleteModal(false)}
                    onConfirm={confirmedDeleteUpsell}
                    itemName={title}
                />
            )}
        </div>
    );
};

export { ReviewSummaryRow };
