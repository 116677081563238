// @ts-nocheck
import suazFavIcon from "Assets/Images/FavIcons/suaz.ico";
import justLimitedFavIcon from "Assets/Images/FavIcons/justLimited.ico";
import proFormationsFavIcon from "Assets/Images/FavIcons/proFormations.ico";
import { CompanyFormationsConfigResponse } from "Apis/ConfigurationApi";
import { useCompanyFormationsContext } from "Context";

export const useTheme = () => {
    const context = useCompanyFormationsContext();

    const getDocumentTitle = (theme: string = context.config?.configuration.theme) => {
        if (!theme) return "";

        if (theme.indexOf("justLimited") > -1) {
            return "Just Limited";
        }
        if (theme.indexOf("proFormations") > -1) {
            return "Pro Formations";
        }
        return "StartUp A-Z";
    };

    const loadTheme = (companyFormationsConfigResponse?: CompanyFormationsConfigResponse, onThemeLoaded?: () => void) => {
        const theme = companyFormationsConfigResponse?.configuration.theme ?? context.config?.configuration.theme;

        if (!theme) return;

        if (theme.indexOf("justLimited") > -1) {
            import("../Styles/_custom.justLimited.scss").then(() => {
                onThemeLoaded();
                document.querySelector("link[rel*='icon']").href = justLimitedFavIcon;
            });
        } else if (theme.indexOf("proFormations") > -1) {
            import("../Styles/_custom.proFormations.scss").then(() => {
                onThemeLoaded();
                document.querySelector("link[rel*='icon']").href = proFormationsFavIcon;
            });
        } else {
            import("../Styles/_custom.scss").then(() => {
                onThemeLoaded();
                document.querySelector("link[rel*='icon']").href = suazFavIcon;
            });
        }

        document.title = getDocumentTitle(theme);
    };

    const getThemeName = (): string => context.config?.configuration?.theme || "suaz";

    const getBusinessName = (): string => {
        const theme = context.config?.configuration?.theme || "suaz";
        switch (theme) {
            case "justLimited":
                return "Just Limited";
            case "proFormations":
                return "Pro Formations";
            default:
                return "Start Up A-Z";
        }
    };

    return {
        loadTheme,
        getThemeName,
        getBusinessName,
        getDocumentTitle,
    };
};
