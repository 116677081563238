import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Modal as BootStrapModal, ModalBody } from "reactstrap";

import { Spinner } from "Components/Spinner";

import styles from "./styles.module.scss";

const Modal = props => (
    <BootStrapModal
        isOpen={props.isOpen}
        centered
        size={props.size}
        modalClassName={props.modalClassName}
        contentClassName={props.className}
        wrapClassName={props.wrapClassName}
        backdrop
        toggle={props.toggle}
        scrollable={props.scrollable}
    >
        <ModalBody className="!tw-p-0 !tw-m-0">
            <div className={classnames((props.objectTitle || props.title) && styles.modalTitle, "d-flex align-items-center")}>
                {props.showSpinner && <Spinner className="mr-3" />}
                {props.objectTitle ? props.objectTitle : props.title && <h3 className="m-0">{props.title}</h3>}
            </div>
            {props.children}
        </ModalBody>
    </BootStrapModal>
);

Modal.propTypes = {
    toggle: PropTypes.func,
    isOpen: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    objectTitle: PropTypes.node,
    showSpinner: PropTypes.bool,
    className: PropTypes.string,
    modalClassName: PropTypes.string,
    wrapClassName: PropTypes.string,
    size: PropTypes.oneOf(["xl", "lg", "md", "sm"]),
    scrollable: PropTypes.bool,
};

Modal.defaultProps = {
    title: "",
    showSpinner: false,
    size: "xl",
    scrollable: false,
};

export { Modal };
