import React from "react";
import { Button } from "reactstrap";
import classnames from "classnames";

import { AddressLookUp } from "../AddressLookup";
import { AddressLookupState } from "../AddressLookup/address.instance";
import { Address } from "../../Types/Address";

interface Props {
    address: Address,
    addressState: AddressLookupState,
    isValidAddress: boolean,

    onSaveSearchedAddress(newAddress: Address): void,

    onEditSavedAddressClick(): void;

    onRemoveSavedAddressClick(): void;

    onManualAddressChange(newAddress: Address, isValid: boolean): void;

    onSwitchToManualAddressClick(): void;

    onSaveManualAddressClick(): void;

    onCancelManualAddressClick(): void;
}

export const AddressInput = ({
    address,
    addressState,
    onSaveSearchedAddress,
    onEditSavedAddressClick,
    onRemoveSavedAddressClick,
    onManualAddressChange,
    onSwitchToManualAddressClick,
    isValidAddress,
    onSaveManualAddressClick,
    onCancelManualAddressClick,
}: Props) => (
    <>
        <div className={classnames("mt-2", addressState === AddressLookupState.ReadOnly && "border border-light rounded p-3")}>
            <AddressLookUp
                hideButtons
                heading=""
                address={{
                    premise: address?.premise || "",
                    addressLine1: address?.addressLine1 || "",
                    addressLine2: address?.addressLine2 || "",
                    city: address?.city || "",
                    postCode: address?.postCode || "",
                }}
                state={addressState}
                onSave={onSaveSearchedAddress}
                onManualAddressChange={onManualAddressChange}
            />
            {addressState === AddressLookupState.ReadOnly && (
                <>
                    <Button color="link" type="button" className="font-weight-bold text-underline" onClick={onEditSavedAddressClick}>Edit</Button>
                    <Button color="link" type="button" className="font-weight-bold text-underline" onClick={onRemoveSavedAddressClick}>Remove</Button>
                </>)}
        </div>

        {addressState === AddressLookupState.Search && (
            <div className="d-flex">
                <div className="mr-3 align-self-center">Or</div>
                <Button
                    color="primary"
                    className="px-5 py-2 bg-transparent text-primary"
                    onClick={onSwitchToManualAddressClick}
                    data-testid="addressManualButton"
                >
                    Enter Manually
                </Button>
            </div>)}

        {addressState === AddressLookupState.Manual && (
            <>
                <Button
                    color="primary"
                    className="font-weight-bold px-5 py-2"
                    disabled={!isValidAddress}
                    onClick={onSaveManualAddressClick}
                    data-testid="saveAddressButton"
                >
                    Save
                </Button>
                <Button
                    color="link"
                    className="font-weight-bold text-underline px-5 py-2"
                    onClick={onCancelManualAddressClick}
                >
                    Cancel
                </Button>
            </>)}
    </>);
