import { useState, useEffect, ReactElement } from "react";

type Sizes = "Sm" | "Md" | "Lg" | "Xl";

interface Props {
    below?: Sizes,
    forAndAbove?: Sizes,
    children?: ReactElement<any>
}

const Visible = ({ forAndAbove, below, children }: Props) => {
    const sm = 576;
    const md = 768;
    const lg = 992;
    const xl = 1200;

    const [width, setWidth] = useState(window.innerWidth);

    const onResize = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    });

    const isInclusiveUp = () => {
        switch (forAndAbove) {
            case "Sm":
                return width > sm;
            case "Md":
                return width > md;
            case "Lg":
                return width > lg;
            case "Xl":
                return width > xl;
            default:
                return false;
        }
    };

    const isInclusiveDown = () => {
        switch (below) {
            case "Sm":
                return width < sm;
            case "Md":
                return width < md;
            case "Lg":
                return width < lg;
            case "Xl":
                return width < xl;
            default:
                return false;
        }
    };

    if (below) {
        return (isInclusiveDown() && children) || null;
    }

    if (forAndAbove) {
        return (isInclusiveUp() && children) || null;
    }

    return null;
};

export { Visible };
