// A Draft.js RichText editor built with draft-js-plugins so we can completely own the styling.
// Draft.js best practice followed by using convertFromRaw and convertToRaw (sparingly) to store state on the server

import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import Editor from "draft-js-plugins-editor";
import "draft-js-static-toolbar-plugin/lib/plugin.css";
import createEmojiPlugin from "draft-js-emoji-plugin";
import "draft-js-emoji-plugin/lib/plugin.css";

import { createBlockQuotePlugin } from "./Plugins/createBlockQuotePlugin";
import styles from "./styles.module.scss";

const RichTextEditor = ({
    placeholder,
    onChange,
    readonly,
    editorState,
    editorClassName,
    editorReadonlyClassName,
    size,
}) => {
    const [{ plugins }] = useState(() => {
        const emojiPlugin = createEmojiPlugin(); // Coming soon

        return {
            plugins: [createBlockQuotePlugin(size), emojiPlugin],
        };
    });

    const [placeholderState, setPlaceholderState] = useState(() => (placeholder || ""));
    const editorRef = useRef(null);

    // Handle Draft.js placeholder hack by calling focus as soon as the user clicks on our wrapped Editor.
    // See Draft.js their own example https://github.com/facebook/draft-js/tree/master/examples/draft-0-10-0/rich
    const focus = () => {
        setPlaceholderState("");
        if (editorRef.current) {
            editorRef.current.focus();
        }
    };

    const onEditorChange = (editorStateValue) => {
        onChange(editorStateValue);
        if (placeholder && editorStateValue.getCurrentContent()
            .getPlainText() === "") {
            setPlaceholderState(placeholder);
        }
    };

    return (
        /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */
        <div className={readonly ? editorReadonlyClassName : editorClassName} onClick={focus} role="textbox">
            <Editor
                editorState={editorState}
                placeholder={placeholderState}
                onChange={onEditorChange}
                plugins={plugins}
                readonly={readonly}
                ref={editorRef}
                spellCheck={!readonly}
            />
        </div>
    );
};

RichTextEditor.propTypes = {
    editorState: PropTypes.shape({}).isRequired,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    readonly: PropTypes.bool,
    editorClassName: PropTypes.string,
    editorReadonlyClassName: PropTypes.string,
    size: PropTypes.oneOf(["sm", "md", "lg"]),
};

RichTextEditor.defaultProps = {
    onChange: () => {
    },
    placeholder: "",
    readonly: false,
    editorClassName: styles.editor,
    editorReadonlyClassName: styles.editorReadonly,
    size: "md",
};

export { RichTextEditor };
