import React from "react";
import classnames from "classnames";

import { useTheme } from "Hooks/useTheme";
import { FileUploadInterface } from "Context/StepperState";
import { DropZoneArea } from "Components/Dropzones/DropZoneArea";

import styles from "./styles.module.scss";

interface FileUploaderInterface {
    filename: string;
    onChange(file): void,
    isLoading: boolean,
    testId: string
}

export const FileUploader = ({ filename, onChange, isLoading, testId }: FileUploaderInterface) => {
    const { getThemeName } = useTheme();
    const buttonStyle = `${getThemeName()}-bg`;

    return (
        <DropZoneArea onAdd={(file: FileUploadInterface) => onChange(file)} disabled={isLoading}>
            <div className={classnames(styles[buttonStyle], "d-flex custom-file cursor-pointer")}>
                <label className="custom-file-label" htmlFor="customFile" data-buttontext={isLoading ? "Loading..." : "Browse"} data-testid={testId}>
                    {
                        filename !== undefined && filename !== "" ? filename : "Browse file to upload"
                    }
                </label>
            </div>
        </DropZoneArea>
    );
};
