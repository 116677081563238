import { useEffect, useState } from "react";

import { AddressLookupState } from "../Components/AddressLookup/address.instance";
import { validateAddress } from "../Utils/validator";
import { Address } from "../Types/Address";

const useAddressHook = (initialAddress: Address) => {
    const [isValidAddress, setIsValidAddress] = useState(false);
    const [addressState, setAddressState] = useState(validateAddress(initialAddress) ? AddressLookupState.ReadOnly : AddressLookupState.Search);

    useEffect(() => {
        const isValidAddressResult = validateAddress(initialAddress);
        setIsValidAddress(isValidAddressResult);
        setAddressState(isValidAddressResult ? AddressLookupState.ReadOnly : AddressLookupState.Search);
    }, [initialAddress]);

    const onSaveSearchedAddress = () => {
        setIsValidAddress(true);
        setAddressState(AddressLookupState.ReadOnly);
    };

    const onSwitchToManualAddressClick = () => {
        setIsValidAddress(false);
        setAddressState(AddressLookupState.Manual);
    };

    const onManualAddressChange = (isValid: boolean) => {
        setIsValidAddress(isValid);
    };

    const onSaveManualAddressClick = () => setAddressState(AddressLookupState.ReadOnly);

    const onCancelManualAddressClick = () => setAddressState(AddressLookupState.Search);

    const onEditSavedAddressClick = () => setAddressState(AddressLookupState.Manual);

    const onRemoveSavedAddressClick = () => {
        setAddressState(AddressLookupState.Search);
    };

    return {
        addressState,
        onSaveSearchedAddress,
        onSwitchToManualAddressClick,
        onManualAddressChange,
        onSaveManualAddressClick,
        onCancelManualAddressClick,
        onEditSavedAddressClick,
        onRemoveSavedAddressClick,
        isValidAddress,
    };
};

export { useAddressHook };
