import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./styles.module.scss";

const CloseButton = props => (
    <button
        type="button"
        onClick={props.onClick}
        className={classnames(styles.button, !props.useRelative && "position-absolute", "border-0 m-0 px-2", props.className)}
        data-testid="close"
    >
        <i className="fa fa-times m-0" />
    </button>
);

CloseButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    useRelative: PropTypes.bool,
};

export default CloseButton;
