import { OfficeAddressKind } from "Pages/OfficeAddressForm/OfficeAddressType";
import { CountryOfRegistrationEnum } from "Pages/OfficeAddressForm/CountryOfRegistrationEnum";
import { Stakeholder } from "../Types/PersonalInformation";
import { Address } from "../Types/Address";

export type StepRoutes =
    "/"
    | "/company-name-success"
    | "/personal-info"
    | "/psc"
    | "/shareholdings"
    | "/business-bank-account"
    | "/contact-information"
    | "/security-information"
    | "/registered-office"
    | "/business-category"
    | "/kyc"
    | "/celebrate-achievement"
    | "/summary"
    | "/summary/sign-up";

export interface StepperState {
    currentPage: StepRoutes;
    nextPage: StepRoutes;
    previousPage?: StepRoutes;
    canSkip?: boolean;
}

export interface FileUploadInterface {
    originalFileName: string,
    uploadedFileName: string,
}

export interface FormState {
    showTerms?: boolean;
    hasAcceptedTerms?: boolean;
    sicSection?: number;
    sicCode?: number;
    countryOfRegistrationKind?: CountryOfRegistrationEnum;
    officeAddressIsVirtualLocation?: boolean;
    officeAddress?: Address; // Company registered office address
    officeAddressType?: OfficeAddressKind;
    question3Answer?: string;
    question2Answer?: string;
    question3?: string;
    question2?: string;
    question1Answer?: string;
    question1?: string;
    hasPreviousAddress?: boolean;
    previousAddress?: Address;
    emailAddress?: string;
    emailAddressConfirmed?: boolean;
    formerLastName?: string;
    formerFirstName?: string;
    hasFormerName?: boolean;
    companyName?: string;
    companyTitle?: string;
    bscUpsellBasketIds: string[];
    proofOfId?: FileUploadInterface;
    proofOfAddress?: FileUploadInterface;
    showBankingterms?: boolean;
    hasAcceptedBankingTerms?: boolean;
    acknowledgedFSCS?: boolean;
    hasConfirmedSubmissionApplication?: boolean;
    stakeholders: Stakeholder[];
}

export const allPages: StepRoutes[] | string[] = [
    "/", "/company-name-success", "/personal-info", "/shareholdings", "/psc", "/business-bank-account", "/contact-information", "/security-information",
    "/registered-office", "/country-of-registration", "/business-category", "/kyc", "/celebrate-achievement", "/summary", "/summary/sign-up"];

export const companyFormationSteps: Array<StepperState> = [
    {
        currentPage: "/",
        nextPage: "/company-name-success",
    },
    {
        currentPage: "/company-name-success",
        previousPage: "/",
        nextPage: "/personal-info",
    },
    {
        currentPage: "/personal-info",
        previousPage: "/company-name-success",
        nextPage: "/shareholdings",
    },
    {
        currentPage: "/shareholdings",
        previousPage: "/personal-info",
        nextPage: "/psc",
    },
    {
        currentPage: "/psc",
        previousPage: "/shareholdings",
        nextPage: "/business-bank-account",
    },
    {
        currentPage: "/business-bank-account",
        previousPage: "/psc",
        nextPage: "/contact-information",
    },
    {
        currentPage: "/contact-information",
        previousPage: "/business-bank-account",
        nextPage: "/security-information",
    },
    {
        currentPage: "/security-information",
        previousPage: "/contact-information",
        nextPage: "/registered-office",
    },
    {
        currentPage: "/registered-office",
        previousPage: "/security-information",
        nextPage: "/business-category",
    },
    {
        currentPage: "/business-category",
        previousPage: "/registered-office",
        nextPage: "/kyc",
    },
    {
        currentPage: "/kyc",
        previousPage: "/business-category",
        nextPage: "/celebrate-achievement",
    },
    {
        currentPage: "/celebrate-achievement",
        previousPage: "/kyc",
        nextPage: "/summary",
    },
    {
        currentPage: "/summary",
        previousPage: "/celebrate-achievement",
        nextPage: "/summary/sign-up",
    },
];
