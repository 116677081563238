import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

import { useTheme } from "../../Hooks/useTheme";
import { slimSignUp } from "Apis/Accounts";
import HideIcon from "Assets/Images/hide.svg";
import ShowIcon from "Assets/Images/show.svg";
import { ConfirmEmailModal } from "Components/ConfirmEmailModal";
import { Spinner } from "Components/Spinner";
import { TermsAndConditions } from "Components/TermsAndConditions";
import Textbox from "Components/Textbox";
import api from "Utils/api";
import { getBrowserId } from "Utils/localStorage";
import { useCompanyFormationsContext } from "../../Context";

import styles from "./styles.module.scss";

const SignUpCheckout = ({ bscUrl, cdnUrl }) => {
    const context = useCompanyFormationsContext();
    const [showPassword, setShowPassword] = useState(false);
    const sendGTMEvent = useGTMDispatch();
    const { getThemeName } = useTheme();

    const [state, setState] = useState({
        email: context.formState.emailAddress,
        loading: false,
        errors: [],
        showPassword: false,
        showConfirmEmailModal: false,
        termsAgreed: false,
        token: "",
        tokenExpiry: 0,
    });

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const sendGTMEventHandler = () => {
        const companyTheme = getThemeName();
        sendGTMEvent({ event: `${companyTheme}_registration_complete` });
    };

    const edit = name => (e) => {
        const value = e.target.value;
        setState((prevState) => {
            const errors = prevState.errors;
            // Removes the "name" error from the list if it exists
            Object.keys(errors)
                .filter(x => x.toLowerCase() === name)
                .forEach(key => delete errors[key]);
            return ({
                ...prevState,
                [name]: value,
                error: "",
                errors,
            });
        });
    };

    const onRegister = (e) => {
        setState(prevState => ({
            ...prevState,
            loading: true,
            error: "",
            errors: {},
        }));
        e.preventDefault();
        slimSignUp({
            ...context.primaryStakeholder,
            dateOfBirth: new Date(context.primaryStakeholder.dateOfBirth),
            securityQuestion1: context.formState.question1,
            securityQuestion1Answer: context.formState.question1Answer,
            securityQuestion2: context.formState.question2,
            securityQuestion2Answer: context.formState.question2Answer,
            securityQuestion3: context.formState.question3,
            securityQuestion3Answer: context.formState.question3Answer,
            phoneNumber: context.formState.mobileNumber,
            email: state.email,
            password: state.password,
            confirmPassword: state.password,
            termsAgreed: state.termsAgreed,
        })
            .then((response) => {
                setState((currentState) => ({
                    ...currentState,
                    loading: false,
                    showConfirmEmailModal: true,
                    ...response,
                }));
                context.resetContext();
                sendGTMEventHandler();
            })
            .catch((error) => {
                if (error.validationFailed || error.errors) {
                    setState((currentState) => ({
                        ...currentState,
                        loading: false,
                        ...error,
                    }));
                } else if (error === "DuplicateUserName") {
                    setState((currentState) => ({
                        ...currentState,
                        loading: false,
                        displayAccountExistsError: true,
                        error: "It seems that the account already exists. Would you like to log in instead?",
                    }));
                } else {
                    setState((currentState) => ({
                        ...currentState,
                        loading: false,
                        error: error.message || api.GENERIC_ERROR,
                    }));
                }
            });
    };

    const onTermsClicked = () => {
        setState((currentState) => ({ ...currentState, termsAgreed: !state.termsAgreed }));
    };

    const getRedirectUrl = () => `${bscUrl}partner-bridge?token=${state.token}&expiry=${state.tokenExpiry}`;

    const isPasswordInvalid = () => state.errors.Password || state.errors.password || state.errors.passwordUpperLower;
    return (
        <div className="d-flex flex-column align-items-center">
            <h1 className="text-center font-weight-bold tw-text-bsc-blue tw-mb-8 sm:tw-w-96 md:tw-w-full">Create your Business Support Club account</h1>
            <Form
                className={classnames(styles.formContainer, "lg:mx-3 d-flex flex-column justify-content-center align-items-center")}
                onSubmit={onRegister}
            >
                <Textbox
                    placeholder="Enter your email"
                    className="mb-2 w-100"
                    type="text"
                    value={state.email || ""}
                    invalid={!!state.errors.Email || !!state.errors.email}
                    onChange={edit("email")}
                    error={state.errors.Email || state.errors.email}
                    inputClasses={styles.bscTextBox}
                />

                <FormGroup className="mb-2 w-100">
                    <InputGroup>
                        <Input
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter your password"
                            className={classnames(styles.bscTextBox, "border-right-0", isPasswordInvalid() && "border-danger")}
                            onChange={edit("password")}
                            value={state.password}
                            invalid={isPasswordInvalid()}
                        />
                        <InputGroupAddon addonType="append">
                            <InputGroupText
                                className={classnames(
                                    isPasswordInvalid() && "border-danger",
                                    "bg-transparent border-left-0",
                                )}
                            >
                                <button type="button" onClick={toggleShowPassword} className={styles.passwordToggle}>
                                    {showPassword ? <HideIcon /> : <ShowIcon />}
                                </button>
                            </InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>
                    <div className="font-size-12 text-danger font-weight-bold tw-mt-0.5">
                        <div>
                            {(state.errors.password || state.errors.Password) && (
                                "Password must be at least 8 characters with mix of letters, numbers & symbols"
                            )}
                        </div>
                        <div>{state.errors.passwordUpperLower}</div>
                    </div>
                </FormGroup>

                <div className={classnames(styles.termsContainer, "py-4")}>
                    <TermsAndConditions
                        showPrivacy
                        checked={state.termsAgreed}
                        onToggle={onTermsClicked}
                        cdnUrl={cdnUrl}
                    />
                </div>

                {state.error && <div className="my-3 alert alert-danger">{state.error}</div>}

                <button
                    type="submit"
                    className="tw-px-6 tw-py-4 tw-mt-3 tw-bg-bsc-blue tw-text-white tw-rounded tw-w-full md:tw-w-4/5 xl:tw-w-1/2 hover:tw-bg-bsc-blue-hover"
                    disabled={!state.termsAgreed}
                >
                    {state.loading ? <Spinner size="20" /> : "Continue to Checkout"}
                </button>
            </Form>

            <a
                href={`${bscUrl}login?browserId=${getBrowserId()}`}
                className="font-size-12 my-3 text-decoration-underline tw-text-bsc-blue tw-font-semibold"
            >
                Already have a BSC account? Log in.
            </a>

            {state.showConfirmEmailModal && <ConfirmEmailModal redirectTo={getRedirectUrl()} />}
        </div>
    );
};

SignUpCheckout.propTypes = {
    bscUrl: PropTypes.string.isRequired,
    cdnUrl: PropTypes.string.isRequired,
};

export { SignUpCheckout };
