import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Form, FormFeedback, FormGroup, Input, InputGroup, InputGroupButtonDropdown } from "reactstrap";
import queryString from "query-string";
import { useLocation } from "react-router";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

import { Spinner } from "../../Components/Spinner";
import { useCompanyFormationsContext } from "../../Context";
import { CompaniesHouseApi } from "../../Apis/CompaniesHouseApi";
import { UpsellTile } from "../../Components/UpsellTile";
import { useUpsell } from "../../Components/UpsellTile/useUpsell";
import { useTheme } from "Hooks/useTheme";
import SuazImage from "../../Assets/Images/suaz-image.svg";
import ProformationsImage from "../../Assets/Images/proFormations-image.svg";

import styles from "./styles.module.scss";

const CompanyNameForm = () => {
    const location = useLocation();
    const context = useCompanyFormationsContext();
    const { getUpsellsByPage, getUpsellProductIdIfInBasket, addUpsellToBscBasketUsingQueryString } = useUpsell();
    const { getThemeName } = useTheme();
    const [isInvalid, setIsInvalid] = useState(false);
    const [isCommunicationError, setIsCommunicationError] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isCheckingNameAvailability, setIsCheckingNameAvailability] = useState(false);
    const upsells = getUpsellsByPage();
    const sendGTMEvent = useGTMDispatch();

    const sendGTMEventHandler = () => {
        const companyTheme = getThemeName();
        sendGTMEvent({ event: `${companyTheme}_check_availability_button_click` });
    };

    const checkAvailability = (companyName?: string | undefined) => {
        setIsCheckingNameAvailability(true);
        CompaniesHouseApi.getCompanyAvailabilityApi(context.formState?.companyName ?? companyName ?? "")
            .then((response) => {
                setIsInvalid(!response);
                setIsCommunicationError(false);
                if (response) {
                    context.navigateToNextStep();
                    sendGTMEventHandler();
                }
            })
            .catch(() => {
                setIsCommunicationError(true);
            })
            .finally(() => {
                setIsCheckingNameAvailability(false);
            });
    };

    useEffect(() => {
        const query = queryString.parse(location.search);
        context.setShowContinueButton(false);
        addUpsellToBscBasketUsingQueryString();
        if (query.c) {
            if (query.t) {
                context.changeFormState("companyTitle", query.t);
            }
            context.changeFormState("companyName", query.c);
            checkAvailability(query.c as string);
        }
        return () => {
            setIsCheckingNameAvailability(false);
        };
    }, []);

    const canCheck = () => {
        const trimmedName = context.formState.companyName?.trim();
        return !isCheckingNameAvailability && trimmedName;
    };

    const toggleDropDown = () => setDropdownOpen(!dropdownOpen);

    const setCompanyType = (companyTitle: string) => {
        context.changeFormState("companyTitle", companyTitle);
    };

    const toLimited = () => setCompanyType("Limited");
    const toLtd = () => setCompanyType("Ltd");

    const onCompanyNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        context.changeFormState("companyName", event?.target?.value);
    };

    const onFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if (canCheck()) {
            checkAvailability();
        }
    };

    return (
        <Form onSubmit={onFormSubmit}>
            <div className="d-flex flex-row">
                <div className={styles.info}>
                    <h3 className="text-main mb-4">
                        {getThemeName() === "proFormations"
                            ? <>Apply to form a company today in a couple of minutes for <b>just £9!</b></>
                            : <>Apply to form a company for <b>FREE</b> in just a couple of minutes!</>}
                    </h3>
                    <h5 className="text-primary mb-4"><b>PLUS...</b></h5>
                    <h6 className="text-main mb-4">
                        <b>FREE</b> introduction to hundreds of national companies dedicated to serving new start up businesses
                        like yours, thanks to the <b>Business Support Club.</b>
                    </h6>
                </div>
                <div>
                    <svg className={styles.image}>
                        {getThemeName() === "proFormations" ? <ProformationsImage /> : <SuazImage />}
                    </svg>
                </div>
            </div>
            <FormGroup>
                <InputGroup className={classnames("mt-4 border rounded", isInvalid && "border-danger")}>
                    <Input
                        invalid={isInvalid}
                        className="border-0 shadow-none text-dark font-weight-bold"
                        placeholder="Enter your company name"
                        value={context.formState.companyName || ""}
                        onChange={onCompanyNameChange}
                        data-testid="companyNameTextbox"
                    />
                    <InputGroupButtonDropdown addonType="append" isOpen={dropdownOpen} toggle={toggleDropDown}>
                        <DropdownToggle className="border-0 shadow-none bg-white">
                            <span>
                                <span>{context.formState.companyTitle}</span>
                                <i className="ml-2 fa fa-chevron-down" />
                            </span>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={toLimited}>Limited</DropdownItem>
                            <DropdownItem onClick={toLtd}>Ltd</DropdownItem>
                        </DropdownMenu>
                    </InputGroupButtonDropdown>
                </InputGroup>

                <FormFeedback className={`d-block ${isInvalid ? "visible" : "invisible"}`} data-testid="invalid">
                    That company name is unavailable. Try another name.
                </FormFeedback>
                <FormFeedback className={`d-block ${isCommunicationError ? "visible" : "invisible"}`}>
                    Something went wrong. Please try again.
                </FormFeedback>
            </FormGroup>
            <div>
                <div className="d-flex align-content-stretch flex-wrap">
                    {upsells.map(upsell => (<UpsellTile key={upsell.upsellId} upsell={upsell} productId={getUpsellProductIdIfInBasket(upsell.products)} />))}
                </div>
            </div>
            <FormGroup className="footerGap">
                <div className="fixed-bottom bg-white w-100 d-flex justify-content-center p-3">
                    <Button
                        id="check-availability"
                        data-testid="checkAvailabilityButton"
                        type="submit"
                        disabled={!canCheck()}
                        className={classnames(styles.button, "font-weight-bold w-100 px-5 py-2 mb-2")}
                        onClick={() => checkAvailability()}
                        color="primary"
                    >
                        {isCheckingNameAvailability ? <span><Spinner size={30} testId="companyNameFormSpinner" /></span> : <>Check availability</>}
                    </Button>
                </div>
            </FormGroup>
        </Form>
    );
};

export { CompanyNameForm };
