import React, { forwardRef, useLayoutEffect } from "react";
import classnames from "classnames";

import styles from "./styles.module.scss";

// React custom scrollbars inserts invalid markup when used with tables
interface Props {
    children: React.ReactNode,
    tBody?: boolean;
    xOverflow?: boolean;
    yOverflow?: boolean;
    className?: string;
    style?;
    onScrollBarVisible?(isVisible): void;
    onScroll?(isAtBottom: boolean): void;
}

const SimpleScrollbar = forwardRef(({
    children,
    tBody,
    xOverflow,
    yOverflow,
    className,
    style = {},
    onScrollBarVisible = () => {},
    onScroll = () => {},
}: Props, scrollElementRef: any) => {
    useLayoutEffect(() => {
        if (scrollElementRef?.current) {
            const { scrollHeight, clientHeight } = scrollElementRef?.current;
            onScrollBarVisible(scrollHeight > clientHeight);
        }
    }, [scrollElementRef?.current?.scrollHeight]);

    const onScrollInternal = () => {
        if (scrollElementRef?.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollElementRef?.current;
            onScroll(scrollTop + (clientHeight + 1) > scrollHeight);
        }
    };

    return (tBody
        ? (
            <tbody
                ref={scrollElementRef}
                onScroll={onScrollInternal}
                data-testid="simple-scrollbar-tbody"
                style={style}
                className={classnames(styles.simpleScrollbars, className && className,
                    xOverflow && styles.simpleScrollbarsOverflowX, yOverflow && styles.simpleScrollbarsOverflowY)}
            >
                {children}
            </tbody>)
        : (
            <div
                ref={scrollElementRef}
                onScroll={onScrollInternal}
                data-testid="simple-scrollbar-div"
                style={style}
                className={classnames(styles.simpleScrollbars, className && className,
                    xOverflow && styles.simpleScrollbarsOverflowX, yOverflow && styles.simpleScrollbarsOverflowY)}
            >
                {children}
            </div>));
});

export { SimpleScrollbar };
