import { useState } from "react";

import { defaultStakeholder, PersonalInformationModalType, Stakeholder, StakeHolderType } from "../Types/PersonalInformation";
import { useCompanyFormationsContext } from "../Context";

const useAdditionalStakeholders = (stakeholderType: StakeHolderType) => {
    const {
        formState,
        changeFormState,
    } = useCompanyFormationsContext();

    const [modalOpen, setModalOpen] = useState<PersonalInformationModalType>();
    const [activeStakeholder, setActiveStakeholder] = useState<Stakeholder>();

    const toggleModal = (modalType: PersonalInformationModalType) => {
        setModalOpen(prev => (prev === modalType ? undefined : modalType));
    };

    const toggleEditModalOpen = (stakeHolder?: Stakeholder) => () => {
        setActiveStakeholder(stakeHolder);
        toggleModal("edit");
    };

    const toggleCreateStakeholderModal = () => {
        setActiveStakeholder(defaultStakeholder(stakeholderType));
        toggleModal("create");
    };

    const toggleRemoveStakeholderModal = (stakeHolder?: Stakeholder) => () => {
        setActiveStakeholder(stakeHolder);
        toggleModal("remove");
    };

    const onCreateSubmit = (stakeholder: Stakeholder) => {
        const typeCountTotals = formState.stakeholders
            .filter(x => x.type === stakeholderType)
            .map(x => x.typeCount ?? 0);

        const newTypeCount = typeCountTotals && typeCountTotals.length > 0
            ? Math.max(...typeCountTotals) + 1
            : 1;

        changeFormState("stakeholders", [...formState.stakeholders, {
            ...stakeholder,
            typeCount: newTypeCount,
            personSignificantControl: stakeholderType === "PersonSignificantControl",
            rightToAppointAndRemoveDirectors: stakeholderType === "PersonSignificantControl",
            shareCount: stakeholderType === "Shareholder" ? 1 : 0,
        } as Stakeholder]);
        toggleCreateStakeholderModal();
    };

    const onEditSubmit = (stakeholder: Stakeholder) => {
        const updatedPeople = formState.stakeholders
            .map(x => (x.id === stakeholder.id ? stakeholder : x));
        changeFormState("stakeholders", updatedPeople);
        toggleEditModalOpen()();
    };

    const onRemoveSubmit = (stakeHolder?: Stakeholder) => () => {
        if (!stakeHolder) return;

        changeFormState("stakeholders", formState.stakeholders.filter(x => x.id !== stakeHolder.id));
        setActiveStakeholder(undefined);
        toggleModal("remove");
    };

    return {
        activeStakeholder,
        modalOpen,
        toggleRemoveStakeholderModal,
        onCreateSubmit,
        onEditSubmit,
        onRemoveSubmit,
        toggleCreateStakeholderModal,
        toggleEditModalOpen,
    };
};

export { useAdditionalStakeholders };
