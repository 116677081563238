import store from "store";

export const JWT = "__tbsc.auth";

export const removeToken = () => { store.remove(JWT); };

export const getToken = () => {
    const accessToken = store.get(JWT);

    if (accessToken) {
        const tokenExpiry = parseInt(accessToken.expiry || "0", 10);

        if (tokenExpiry * 1000 > new Date().getTime()) {
            return accessToken.token;
        }
    }

    return null;
};

export const hasToken = () => Boolean(getToken());

export const decodeToken = () => {
    try {
        const token = getToken();
        const base64Url = token.split(".")[1];
        return JSON.parse(atob(base64Url));
    } catch (e) {
        removeToken();
        return { error: "Invalid token" };
    }
};
