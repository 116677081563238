import React, { useEffect, useState } from "react";
import { Route, Router, Switch } from "react-router";
import classnames from "classnames";
import { useHistory } from "react-router-dom";

import { ProgressBar } from "Components/ProgressBar";
import { allPages, companyFormationSteps } from "Context/StepperState";
import { SimpleScrollbar } from "Components/SimpleScrollbar";
import { Logo } from "Components/Logo";
import { FormFooter } from "Components/FormFooter";
import { useTheme } from "Hooks/useTheme";
import { SummaryForm } from "Pages/SummaryForm";
import { BusinessCategoryForm } from "Pages/BusinessCategoryForm";
import { OfficeAddressForm } from "Pages/OfficeAddressForm";
import { SecurityInformationForm } from "Pages/SecurityInformationForm";
import { CompanyNameForm } from "Pages/CompanyNameForm";
import { CompanyNameSuccessForm } from "Pages/CompanyNameSuccessForm";
import { PersonalInformationForm } from "Pages/PersonalInformationForm";
import { ContactInformationForm } from "Pages/ContactInformationForm";
import { KYCForm } from "Pages/KYC";
import { CelebrateAchievementForm } from "Pages/CelebrateAchievementForm";
import { BusinessBankAccountForm } from "Pages/BusinessBankAccountForm";

import { useCompanyFormationsContext } from "./Context";
import styles from "./styles.module.scss";
import { PersonSignificantControlForm } from "./Pages/PersonSignificantControlForm";
import { ShareholdingsForm } from "./Pages/ShareholdingsForm";

const AppRouter = () => {
    const context = useCompanyFormationsContext();
    const history = useHistory();

    const [scrollBarIsAtBottom, setScrollBarIsAtBottom] = useState(false);

    const onScroll = (isAtBottom) => {
        setScrollBarIsAtBottom(isAtBottom);
    };

    const { getThemeName } = useTheme();

    const companyPhoneNumber = () => {
        let phoneNumber: string;
        if (context.config!.configuration.theme.match("suaz")) {
            phoneNumber = "0330 320 1929";
        } else if (context.config!.configuration.theme.match("proFormations")) {
            phoneNumber = "0330 320 1948";
        } else {
            phoneNumber = " ";
        }

        return phoneNumber;
    };

    useEffect(() => {
        context.scrollBarElementRef.current.scrollTop = 0;
        // Handle hard refresh or navigation to a step directly
        if (context.formState.companyName === undefined
            || !allPages.some(x => x === history.location.pathname)) { // Handle 404's
            history.push("/");
        }
    }, [history.location.pathname]);

    return (
        <>
            <div className={classnames(styles.container, "d-flex flex-row text-main")}>
                <div className={classnames(styles.formWrapper, "p-0 flex-grow-1 d-flex flex-column")}>
                    <div>
                        <div className="d-flex justify-content-center flex-row">
                            <div className={classnames(styles.header, "d-flex justify-content-between align-items-center flex-row w-100")}>
                                <span className={classnames(styles.logo, "my-3 ml-3 ml-md-0")}>
                                    <Logo brand={getThemeName()} />
                                </span>
                                <span className="mr-3 mr-md-0">Contact Us: <b>{companyPhoneNumber()}</b></span>
                            </div>
                        </div>
                        <ProgressBar
                            minimumValue={0}
                            maximumValue={companyFormationSteps.length}
                            value={companyFormationSteps.findIndex(x => x.currentPage === context.currentStep.currentPage) + 1}
                        />
                    </div>

                    <div className="d-flex justify-content-center flex-grow-1 mt-5">
                        <SimpleScrollbar
                            yOverflow
                            className={classnames(styles.formContainer)}
                            onScroll={onScroll}
                            ref={context.scrollBarElementRef}
                        >
                            <div
                                className={classnames(
                                    styles.formContainer, "d-flex w-100",
                                )}
                            >
                                <div className="w-100 ml-3">
                                    <Router history={history}>
                                        <Switch>
                                            <Route exact path="/" component={CompanyNameForm} />
                                            <Route path="/company-name-success" component={CompanyNameSuccessForm} />
                                            <Route path="/business-bank-account" component={BusinessBankAccountForm} />
                                            <Route path="/personal-info" component={PersonalInformationForm} />
                                            <Route path="/shareholdings" component={ShareholdingsForm} />
                                            <Route path="/psc" component={PersonSignificantControlForm} />
                                            <Route path="/contact-information" component={ContactInformationForm} />
                                            <Route path="/security-information" component={SecurityInformationForm} />
                                            <Route path="/registered-office" component={OfficeAddressForm} />
                                            <Route path="/business-category" component={BusinessCategoryForm} />
                                            <Route path="/kyc" component={KYCForm} />
                                            <Route path="/celebrate-achievement" component={CelebrateAchievementForm} />
                                            <Route path="/summary" component={SummaryForm} />
                                        </Switch>
                                    </Router>
                                </div>
                            </div>
                        </SimpleScrollbar>
                    </div>
                    <FormFooter scrollBarIsAtBottom={scrollBarIsAtBottom} />
                </div>
            </div>
        </>);
};

export { AppRouter };
