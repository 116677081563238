import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";

import Modal from "../Modal";

const ConfirmDeleteBasketItemModal = ({ onClose, onConfirm, itemName }) => (
    <Modal
        isOpen
        objectTitle={
            <>
                <h3 className="ml-0 d-none d-md-flex">Confirm Removal</h3>
                <h5 className="ml-0 d-flex d-md-none">Confirm Removal</h5>
            </>
        }
    >
        <p>Are you sure that you want to remove <strong>{itemName}</strong>?</p>

        <div className="text-center mt-4 d-flex flex-column flex-md-row justify-content-center">
            <Button color="danger" type="submit" onClick={onConfirm} className="mb-1 mr-0 mr-md-1">
                Yes, Remove
            </Button>
            <Button color="secondary" type="submit" onClick={onClose} className="mb-1">
                No, Keep it
            </Button>
        </div>

    </Modal>
);

ConfirmDeleteBasketItemModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    itemName: PropTypes.string.isRequired,
};

export { ConfirmDeleteBasketItemModal };
