import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Logo } from "Components/Logo";
import { SignUp } from "Components/SignUp";
import { SimpleScrollbar } from "Components/SimpleScrollbar";
import { useTheme } from "Hooks/useTheme";
import BuildAPackageIcon from "../../Assets/Images/build-a-package.svg";
import BuildPackageIcon from "../../Assets/Images/build-package.svg";
import PackageCommissionIcon from "../../Assets/Images/package-commission.svg";
import MessagingIcon from "../../Assets/Images/messaging.svg";
import MarketplaceIcon from "../../Assets/Images/marketplace.svg";

import styles from "./styles.module.scss";

interface Props {
    bscUrl: string;
    cdnUrl: string;
}

const SuazToBscSignUpBridge = ({ bscUrl, cdnUrl }: Props) => {
    const cardClass = "d-flex flex-column justify-content-center align-items-center p-3 p-md-5 bg-muted rounded shadow m-3";
    const { getBusinessName, getThemeName } = useTheme();

    return (
        <div>
            <SimpleScrollbar className="vh-100 vw-100" yOverflow>
                <div className={classnames(styles.header, "py-5")}>
                    <div className="container my-md-5 mb-3">
                        <div className={classnames(styles.signUpContainer, "rounded-lg bg-white")}>
                            <SignUp bscUrl={bscUrl} cdnUrl={cdnUrl} displayFulfilText />

                            <div className="bg-light border-left d-flex flex-column align-items-center p-3 p-md-5">
                                <div
                                    className={classnames(styles.logo, "bg-secondary rounded-circle p-4 mb-3 d-flex align-items-center")}
                                >
                                    <Logo brand={getThemeName()} />
                                </div>
                                <p className="font-weight-bold mt-3">
                                    {getBusinessName()} used Business Support Club to provide you
                                    with your Company Formations service today.
                                </p>
                                <p>
                                    They used us because we’re a one-stop shop where they can
                                    provide their goods, interact with customers like you, and
                                    provide you with the best possible buying experience.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classnames(styles.loveBsc, "position-relative")}>
                    <div className="d-flex justify-content-center align-items-center m-lg-5 m-3">
                        <h1 className="text-center d-none d-lg-block">
                            You&#39;ll <span className="text-danger">love</span> the Business Support Club!
                        </h1>
                        <h2 className="text-center d-block d-lg-none">
                            You&#39;ll <span className="text-danger">love</span> the Business Support Club!
                        </h2>
                    </div>
                    <div className="m-md-5 m-3 pr-md-5">
                        <div className={styles.cards}>
                            <div className={cardClass}>
                                <MarketplaceIcon />
                                <span>We&#39;re your one-stop shop for hundreds of B2B products and services.</span>
                            </div>
                            <div className={cardClass}>
                                <BuildPackageIcon />
                                <span>We give small businesses like you better control over your suppliers.</span>
                            </div>
                            <div className={cardClass}>
                                <MessagingIcon />
                                <span>Manage all your customer messages and orders in just one place.</span>
                            </div>
                            <div className={cardClass}>
                                <PackageCommissionIcon />
                                <span>We’re here to help your business grow. Everything you need in one platform.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classnames(styles.manageBusiness)}>
                    <div className="d-flex align-items-center justify-content-center h-100 mt-5 mt-lg-5">
                        <div className="rounded-circle bg-info p-3">
                            <BuildAPackageIcon />
                        </div>
                    </div>

                    <div
                        className="d-flex flex-column justify-content-center mx-3 mr-lg-5 pr-0 pr-lg-5 mt-5 text-white text-center text-lg-left"
                    >
                        <h1>Manage your business with ease</h1>
                        <h5 className="mb-3 font-weight-normal">
                            Find suppliers, manage key areas of your company, and store your information – all in one
                            platform.
                        </h5>
                        <p className="text-info font-weight-bold">One account and one login</p>
                        <p>
                            Sign up, make purchases, and manage your company
                            through one account.
                        </p>

                        <p className="text-info font-weight-bold">One checkout for multiple suppliers</p>
                        <p>
                            With all your information stored in your account, checking out your purchases will be
                            quicker
                            and
                            more convenient.
                        </p>

                        <p className="text-info font-weight-bold">Source new suppliers</p>
                        <p>Use the platform to find and source from other businesses.</p>

                        <p className="text-info font-weight-bold">Sell your goods</p>
                        <p>
                            Contribute to the community and make your products available in the Marketplace.
                        </p>
                    </div>
                </div>

                <div className={classnames(styles.signUpFooter, "py-5")}>
                    <div className={classnames(styles.signUpContainer, "container")}>
                        <div className="d-flex flex-column align-items-center p-5 rounded-lg bg-white  py-md-5 pb-3">
                            <SignUp bscUrl={bscUrl} cdnUrl={cdnUrl} displayFulfilText={false} />
                        </div>
                    </div>
                </div>
            </SimpleScrollbar>
        </div>
    );
};

SuazToBscSignUpBridge.propTypes = {
    bscUrl: PropTypes.string.isRequired,
    cdnUrl: PropTypes.string.isRequired,
};

export { SuazToBscSignUpBridge };
