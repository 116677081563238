import queryString from "query-string";

import history from "../../appHistory";
import { UpsellItemType, UpsellProductType } from "Apis/ConfigurationApi";
import { useCompanyFormationsContext } from "../../Context";
import { useTheme } from "../../Hooks/useTheme";

export const useUpsell = () => {
    const context = useCompanyFormationsContext();
    const { getDocumentTitle } = useTheme();

    const getUpsellsByPage = (): UpsellItemType[] => context.config?.upsells.find(x => x.pageUri === history.location.pathname)?.upsells ?? [];

    const getUpsellTile = (upsellTileName: string): UpsellItemType | undefined => getUpsellsByPage()
        ?.find(x => x.upsellName === upsellTileName);

    const getVirtualOfficeUpsellTiles = (): UpsellItemType[] | null => getUpsellsByPage()
        ?.filter(x => x.upsellContainsVirtualAddress);

    const getVirtualOfficeUpsellProducts = (): UpsellProductType[] | undefined => getVirtualOfficeUpsellTiles()
        ?.flatMap(x => x.products);

    const getUpsellById = (upsellId: string): UpsellItemType | undefined => {
        const allUpsells = context.config?.upsells.reduce((accumulator, currentValue) => {
            currentValue.upsells.forEach(x => accumulator.push(x));
            return accumulator;
        }, [] as UpsellItemType[]);

        return allUpsells?.find(x => x.upsellId === upsellId);
    };

    const isInBasket = (productId: string) => (context.formState.bscUpsellBasketIds.some(x => x === productId));

    const getUpsellProductIdIfInBasket = (upsellProducts: UpsellProductType[]) => ({ ...upsellProducts.find(x => isInBasket(x.id)) }?.id ?? "");

    const addProductToBscBasket = (productId: string) => {
        context.changeFormState("bscUpsellBasketIds", context.formState.bscUpsellBasketIds.concat([productId]));
    };

    const removeProductFromBscBasket = (productId: string) => {
        context.changeFormState("bscUpsellBasketIds", context.formState.bscUpsellBasketIds.filter(x => x !== productId));
    };

    const addUpsellProductToBscBasket = (upsellItem: UpsellItemType, selectedProductId: string) => {
        // remove upsell products from bscUpsellBasket, before add new selection
        let bscUpsellBasketIdsExcludingUpsellProductIds: string[] = context.formState.bscUpsellBasketIds
            .reduce((accumulator, currentValue) => {
                if (!upsellItem.products.some(x => x.id === currentValue)) {
                    accumulator.push(currentValue);
                }
                return accumulator;
            }, [] as string[]);

        if (getUpsellsByPage()
            .some(x => x.oneUpsellOnly)) {
            bscUpsellBasketIdsExcludingUpsellProductIds = context.formState.bscUpsellBasketIds
                .reduce((accumulator, currentValue) => {
                    if (!getUpsellsByPage()
                        .some(x => x.upsellId === upsellItem.upsellId)) {
                        accumulator.push(currentValue);
                    }
                    return accumulator;
                }, [] as string[]);
        }

        context.changeFormState("bscUpsellBasketIds", [...bscUpsellBasketIdsExcludingUpsellProductIds, selectedProductId]);

        // The document title may have been set due to preselected items in the original querystring
        // If the user is changing their choice, we need to reset the title to its default value
        if (history.location.pathname === "/") {
            document.title = getDocumentTitle();
        }
    };

    const addUpsellToBscBasketUsingQueryString = () => {
        const query = queryString.parse(history.location.search);

        if (!query.upsells) {
            return;
        }

        let upsellDataInQueryString: string[] = [];
        if (Array.isArray(query.upsells)) {
            upsellDataInQueryString = [...query.upsells];
        } else {
            upsellDataInQueryString = [query.upsells as string];
        }

        upsellDataInQueryString.forEach(encodedUpsellData => {
            const decodedUpsellData = JSON.parse(atob(encodedUpsellData)) as { upsellId: string, productId: string };
            const upsellToAdd = getUpsellById(decodedUpsellData.upsellId);
            if (upsellToAdd) {
                addUpsellProductToBscBasket(upsellToAdd, decodedUpsellData.productId);
            }
        });

        if (upsellDataInQueryString.length === 1 && history.location.pathname === "/") {
            const upsells = getUpsellsByPage();

            const upsellId = (JSON.parse(atob(upsellDataInQueryString[0])) as { upsellId: string }).upsellId;

            const upsellName = upsells
                .find(x => x.upsellId === upsellId)
                ?.upsellName;

            if (upsellName) {
                document.title = `${upsellName} | ${getDocumentTitle()}`;
            }
        }
    };

    return {
        getUpsellProductIdIfInBasket,
        getUpsellTile,
        getUpsellsByPage,
        getVirtualOfficeUpsellProducts,
        isInBasket,
        addProductToBscBasket,
        removeProductFromBscBasket,
        addUpsellProductToBscBasket,
        addUpsellToBscBasketUsingQueryString,
    };
};
