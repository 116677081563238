import React from "react";
import { Link, Route, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { NavLink } from "reactstrap";

import PreviewImage from "Assets/Images/device-previews.png";
import { Modal } from "Modals/Modal";
import CloseButton from "Components/CloseButton";
import { SignUpCheckout } from "Components/SignUpCheckout";
import { useCompanyFormationsContext } from "../../Context";

interface Props {
    bscUrl: string;
    cdnUrl: string;
}

const SignUpModal = ({
    bscUrl,
    cdnUrl,
}: Props) => {
    const context = useCompanyFormationsContext();
    const history = useHistory();
    const closeModal = () => history.push("/summary");

    return (
        <Route path="/summary/sign-up">
            <Modal isOpen>
                <CloseButton onClick={closeModal} className="tw-text-white lg:tw-text-bsc-blue" />
                <div className="tw-flex tw-flex-col lg:tw-flex-row">
                    <div className="tw-basis-2/5 tw-bg-twilight-blue tw-text-bsc-yellow tw-pt-20 tw-pb-6 tw-px-12 tw-relative">
                        <p className="tw-text-2xl">
                            Manage your order on Business Support Club and find other suppliers like us!
                        </p>
                        <img
                            src={PreviewImage}
                            alt="Business Support Club website preview on laptop, tablet and mobile"
                            className="tw-absolute lg:tw-relative lg:tw-top-0 -tw-top-20 lg:tw-w-full lg:tw-mb-4 tw-w-64 tw-mx-auto tw-right-0 tw-left-0"
                        />
                        <NavLink
                            className="tw-text-bsc-yellow tw-p-0"
                            tag={Link}
                            to={{
                                pathname: "/sign-up",
                                state: {
                                    emailAddress: context.formState.emailAddress,
                                    firstName: context.primaryStakeholder?.firstName,
                                    lastName: context.primaryStakeholder?.lastName,
                                    phoneNumber: context.primaryStakeholder?.mobileNumber,
                                },
                            }}
                        >
                            Know more about us <i className="fas fa-arrow-circle-right" />
                        </NavLink>
                    </div>
                    <div className="tw-basis-3/5 tw-flex tw-justify-center tw-items-center tw-p-6 t lg:tw-p-12">
                        <SignUpCheckout bscUrl={bscUrl} cdnUrl={cdnUrl} />
                    </div>
                </div>
            </Modal>
        </Route>
    );
};

SignUpModal.propTypes = {
    bscUrl: PropTypes.string.isRequired,
    cdnUrl: PropTypes.string.isRequired,
};

export { SignUpModal };
