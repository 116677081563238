import React, { useCallback, useEffect, useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";

const Textbox = props => {
    const [isRequiredValidationFailed, setIsRequiredValidationFailed] = useState(false);
    const [isValidTextFailed, setIsValidTextFailed] = useState(false);
    const [canValidate, setCanValidate] = useState(props.validateOnMount);

    const isValid = (value) => {
        const regex = new RegExp(props.regex);
        return (regex.test(value));
    };

    useEffect(() => {
        if (canValidate) {
            setIsRequiredValidationFailed(props.isRequired && !props.value);
            setIsValidTextFailed(props.value && !isValid(props.value));
        } else if (isRequiredValidationFailed) {
            setIsRequiredValidationFailed(false);
        } else if (isValidTextFailed) {
            setIsValidTextFailed(false);
        }
    }, [canValidate, isRequiredValidationFailed, props.value, props.isRequired]);

    const onBlur = useCallback(() => {
        setCanValidate(true);
        if (props.onBlur) {
            props.onBlur();
        }
    }, [props]);

    return (
        <FormGroup className={classnames(props.className, "text-box", props.isRequired && "text-box-required")}>
            {props.label && <Label>{props.label}</Label>}
            {props.formFeedbackTop && (
                <div className="text-danger font-weight-bolder font-size-12 h-20px mb-1">
                    {props.error || ((isValidTextFailed || isRequiredValidationFailed) && <>{props.isRequiredError}</>)}
                </div>
            )}
            <Input
                key={props.disabled ? "disabled" : "enabled"}
                onKeyUp={props.onKeyUp}
                type={props.type}
                name={props.name}
                placeholder={props.placeholder}
                value={props.value || ""}
                invalid={isRequiredValidationFailed || isValidTextFailed || !!props.error}
                onChange={props.onChange}
                disabled={props.disabled}
                className={props.inputClasses}
                onBlur={onBlur}
                onFocus={props.onFocus}
                autoFocus={!props.disabled && props.autoFocus}
                style={props.inputStyle}
                data-testid={props["data-testid"] || ""}
            />
            {!props.formFeedbackTop && (props.error || isRequiredValidationFailed) && (
                <FormFeedback className="font-weight-bold">{props.error || (isRequiredValidationFailed && <>{props.isRequiredError}</>)}</FormFeedback>
            )}
            {!props.formFeedbackTop && isValidTextFailed && (
                <FormFeedback className="font-weight-bold">{props.error || (isValidTextFailed && <>{props.isInvalidError}</>)}</FormFeedback>
            )}
        </FormGroup>
    );
};

Textbox.propTypes = {
    onChange: PropTypes.func, // not required is disabled
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyUp: PropTypes.func,
    isRequired: PropTypes.bool,
    isRequiredError: PropTypes.string,
    isInvalidError: PropTypes.string,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]),
    type: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    className: PropTypes.string,
    inputClasses: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    inputStyle: PropTypes.object,
    autoFocus: PropTypes.bool,
    "data-testid": PropTypes.string,
    validateOnMount: PropTypes.bool,
    formFeedbackTop: PropTypes.bool,
    regex: PropTypes.string,
};

Textbox.defaultProps = {
    disabled: false,
    type: "text",
    name: "",
    isRequired: false,
    isRequiredError: "This field is required",
    isInvalidError: "Text is invalid",
    autoFocus: false,
    validateOnMount: false,
    formFeedbackTop: false,
    regex: ".", // place regex here to validate on
};

export default Textbox;
