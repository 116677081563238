import React from "react";

import JustLimitedSvg from "../../Assets/Images/Logos/justLimited-logo-svg.svg";
import ProFormationsSvg from "../../Assets/Images/Logos/proFormations-logo-svg.svg";
import SUAZSvg from "../../Assets/Images/Logos/suaz-logo-svg.svg";

import styles from "./styles.module.scss";

interface Props {
    brand?: string
}

const Logo = ({ brand }: Props) => {
    switch (brand) {
        case "justLimited":
            return (
                <svg className={styles.image}>
                    <JustLimitedSvg />
                </svg>
            );
        case "proFormations":
            return (
                <svg className={styles.image}>
                    <ProFormationsSvg />
                </svg>
            );
        default:
            return (
                <svg className={styles.image}>
                    <SUAZSvg />
                </svg>
            );
    }
};

export { Logo };
