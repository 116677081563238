import React from "react";

import { useCompanyFormationsContext } from "../../Context";
import { ReviewSummaryRow } from "Components/ReviewSummaryRow";
import { OfficeAddressKind } from "Pages/OfficeAddressForm/OfficeAddressType";
import { formatAddressForDisplay } from "Pages/SummaryForm/addressHelper";
import { sicSections } from "../../Pages/BusinessCategoryForm/sicSections";

const CompanyInformationSummary: React.FC = () => {
    const context = useCompanyFormationsContext();

    const directors = context.formState.stakeholders
        .filter(x => x.type === "Director" && !x.isPrimary);

    const shareholders = context.formState.stakeholders
        .filter(x => x.shareCount > 0);

    const pscs = context.formState.stakeholders
        .filter(x => x.personSignificantControl);

    return (
        <div className="mb-4">
            <h5 className="my-4">Your company information</h5>
            <ReviewSummaryRow
                title="Company name"
                description={`${context.formState.companyName} ${context.formState.companyTitle}`}
                target="/"
            />
            <ReviewSummaryRow
                title="Full name"
                description={`${context.primaryStakeholder.firstName} ${context.primaryStakeholder.lastName}`}
                target="/personal-info"
            />
            <ReviewSummaryRow
                title="Registered office Address"
                description={formatAddressForDisplay(context.formState.officeAddressType === OfficeAddressKind.VirtualAddress
                    ? context.formState.officeAddress?.city
                    : context.formState.officeAddress) || ""}
                target="/registered-office"
                isAddress
            />
            <ReviewSummaryRow
                title="Business category"
                description={`${sicSections.find(x => x.value === context.formState.sicSection)?.label}`}
                target="/business-category"
            />
            <ReviewSummaryRow
                title="Business type"
                description={`${context.config?.sicCodes.find(x => x.id === context.formState.sicCode)?.description} (${context.formState.sicCode})`}
                target="/business-category"
            />
            {directors?.length > 0 && (
                <ReviewSummaryRow
                    title="Additional Directors"
                    target="/personal-info"
                    scrollBottom
                >
                    <div>
                        {directors.map(director => (
                            <div key={director.id}>
                                {director.firstName} {director.lastName}
                            </div>
                        ))}
                    </div>
                </ReviewSummaryRow>
            )}
            {shareholders?.length > 0 && (
                <ReviewSummaryRow
                    title="Shareholders"
                    target="/shareholdings"
                >
                    <div>
                        {shareholders.map(shareholder => (
                            <div key={shareholder.id}>
                                {shareholder.firstName} {shareholder.lastName}
                            </div>
                        ))}
                    </div>
                </ReviewSummaryRow>
            )}
            {pscs?.length > 0 && (
                <ReviewSummaryRow
                    title="PSCs"
                    target="/psc"
                >
                    <div>
                        {pscs.map(psc => (
                            <div key={psc.id}>
                                {psc.firstName} {psc.lastName}
                            </div>
                        ))}
                    </div>
                </ReviewSummaryRow>
            )}
        </div>
    );
};

export { CompanyInformationSummary };
