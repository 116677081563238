const addressLookupConfiguration = {
    createContext: () => { },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dispatchError: (error, context) => { },
    loadingIcon: "",
};

export {
    addressLookupConfiguration,
};
