import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { debounceTime } from "rxjs/operators";
import { Subject } from "rxjs";

import Textbox from "../Textbox";

const DebounceTextbox = ({ placeholder, invalid, error, callBack, className, inputClasses,
    inputStyle, debounceTimeout, autoFocus, value, disabled,
    onBlur, onFocus, onKeyUp }) => {
    const [search, onSearch] = useState(value || "");
    const searchSubject = useRef({
        setUp: false,
        sub: null,
    });
    const isFirstSearch = useRef(true);

    useEffect(() => {
        if (!searchSubject.current.setUp) {
            searchSubject.current.setUp = true;
            searchSubject
                .current
                .sub = new Subject();
            searchSubject.current.sub
                .pipe(debounceTime(debounceTimeout))
                .subscribe((newValue) => { callBack(newValue); });
        }
    }, [debounceTimeout, callBack]);

    const setNewValue = (newValue) => {
        onSearch(newValue);
        searchSubject.current.sub.next(newValue);
    };

    const onChange = (e) => {
        setNewValue(e.target.value);
    };

    useEffect(() => {
        if (isFirstSearch.current) {
            isFirstSearch.current = false;
        } else {
            setNewValue(value);
        }
    }, [value]);

    return (
        <>
            <Textbox
                disabled={disabled}
                autoFocus={autoFocus}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyUp={onKeyUp}
                placeholder={placeholder}
                invalid={invalid}
                error={error}
                name="postcode"
                value={search}
                inputClasses={inputClasses}
                inputStyle={inputStyle}
                className={className}
            />
        </>
    );
};

DebounceTextbox.propTypes = {
    callBack: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    invalid: PropTypes.bool,
    error: PropTypes.string,
    className: PropTypes.string,
    inputClasses: PropTypes.string,
    value: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    inputStyle: PropTypes.object,
    debounceTimeout: PropTypes.number,
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyUp: PropTypes.func,
};

DebounceTextbox.defaultProps = {
    debounceTimeout: 500,
};

export { DebounceTextbox };
