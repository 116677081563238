import { Address } from "Types/Address";

export const formatAddressForDisplay = (address: Address | string | undefined): string => {
    if (typeof address === "string") {
        return `Virtual address: ${address}`;
    }
    if (address) {
        return [
            address.premise,
            address.addressLine1,
            address.addressLine2,
            address.city,
            address.postCode,
            address.country]
            .filter(_ => !!_)
            .join(",\r\n");
    }
    return "";
};
