import React from "react";
import classnames from "classnames";
import { Button } from "reactstrap";

import { useCompanyFormationsContext } from "../../Context";

import styles from "./styles.module.scss";

export const FormFooter = ({ scrollBarIsAtBottom }: { scrollBarIsAtBottom: boolean }) => {
    const context = useCompanyFormationsContext();
    const backClass = (context.currentStep.previousPage && context.currentStep.currentPage !== "/company-name-success") ? "" : "d-none";
    const largeButton = context.currentStep.currentPage === "/company-name-success";

    return (
        <>
            {context.showContinueButton && (
                <div className="d-flex justify-content-center fixed-bottom bg-white">
                    <div className={classnames(styles.formFooter, "d-flex w-100")}>
                        <div className="w-100 mx-3 d-flex justify-content-center pt-3">
                            <div className={classnames(scrollBarIsAtBottom && styles.hideScrollbarBottomBlur, styles.scrollbarBottomBlur)} />
                            <Button
                                color="light"
                                onClick={context.navigateToPreviousStep}
                                data-testid="back"
                                className={classnames("font-weight-bold px-5 py-2", backClass)}
                            >Back
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                className={classnames("font-weight-bold px-5 py-2 ml-3", largeButton && styles.largeButton)}
                                data-testid="continue"
                                onClick={context.navigateToNextStep}
                                disabled={context.disableContinueButton}
                            >
                                {!context.isLoading
                                    ? context.proceedLabel
                                    : (<span>Loading <i className={classnames(styles.continueLoadingIcon, "fas fa-circle-notch fa-spin")} /></span>)}
                            </Button>
                            {context.currentStep.canSkip && (
                                <Button
                                    type="submit"
                                    color="light"
                                    className="font-weight-bold px-5 py-2 ml-3"
                                    data-testid="skip"
                                    onClick={context.navigateToNextStep}
                                >
                                    {!context.isLoading
                                        ? "Skip"
                                        : (<span>Loading <i className={classnames(styles.continueLoadingIcon, "fas fa-circle-notch fa-spin")} /></span>)}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>)}
        </>);
};
