import React from "react";

import { FormLabel } from "../../Components/FormLabel";
import { useCompanyFormationsContext } from "../../Context";
import { Stakeholder } from "../../Types/PersonalInformation";
import { PersonalInformationModal } from "../../Modals/PersonalInformationModal";
import { ConfirmCancelModal } from "../../Modals/ConfirmCancelModal";
import { sort } from "../../Utils/sortingUtil";
import { useAdditionalStakeholders } from "../../Hooks/useAdditionalStakeholders";
import RadioButton from "../../Components/RadioButton";
import { enumDisplay } from "../../Utils/formatStringHelper";
import { StakeholderActionButtons } from "../../Components/StakeholderActionButtons";
import api from "../../Utils/api";

const PersonSignificantControlForm = () => {
    const context = useCompanyFormationsContext();

    const {
        activeStakeholder,
        modalOpen,
        toggleCreateStakeholderModal,
        onCreateSubmit,
        toggleEditModalOpen,
        onEditSubmit,
        toggleRemoveStakeholderModal,
        onRemoveSubmit,
    } = useAdditionalStakeholders("PersonSignificantControl");

    const onRadioChange = (additionalPerson: Stakeholder, property: keyof Stakeholder, invert: boolean = false) => (value: boolean) => {
        const shareHoldersUpdated = context.formState.stakeholders
            .map(x => {
                if (x.id === additionalPerson.id) {
                    return {
                        ...x,
                        [property]: invert ? !value : value,
                    };
                }

                return x;
            });

        context.changeFormState("stakeholders", shareHoldersUpdated);
    };

    const renderListing = (stakeholder: Stakeholder) => (
        <div className="tw-flex tw-flex-col tw-mt-10 tw-space-y-4" key={stakeholder.id}>
            <h5 className="-tw-mb-1">
                {stakeholder.isPrimary
                    ? "Primary Applicant"
                    : `${enumDisplay(stakeholder.type)} ${stakeholder.typeCount} - ${stakeholder.firstName} ${stakeholder.lastName}`}
            </h5>
            <div className="tw-flex tw-flex-col tw-space-y-3">
                <FormLabel>
                    {stakeholder.isPrimary
                        ? "Is the primary applicant a PSC?"
                        : `Is '${enumDisplay(stakeholder.type)} ${stakeholder.typeCount}' a PSC?`}
                </FormLabel>
                <div className="tw-flex tw-flex-row tw-space-x-2">
                    <RadioButton
                        checked={stakeholder.personSignificantControl}
                        onChange={onRadioChange(stakeholder, "personSignificantControl")}
                        id={`${stakeholder.id}-yes-personSignificantControl`}
                        label="Yes"
                    />
                    <RadioButton
                        checked={!stakeholder.personSignificantControl}
                        onChange={onRadioChange(stakeholder, "personSignificantControl", true)}
                        id={`${stakeholder.id}-no-personSignificantControl`}
                        label="No"
                    />
                </div>
            </div>
            <div className="tw-flex tw-flex-col tw-space-y-3">
                <FormLabel>
                    {stakeholder.isPrimary
                        ? "Does the primary applicant have the right to appoint or remove the majority of the board of directors?"
                        : `Does '${enumDisplay(stakeholder.type)} ${stakeholder.typeCount}' 
                            have the right to appoint or remove the majority of the board of directors?`}
                </FormLabel>
                <div className="tw-flex tw-flex-row tw-space-x-2">
                    <RadioButton
                        checked={stakeholder.rightToAppointAndRemoveDirectors}
                        onChange={onRadioChange(stakeholder, "rightToAppointAndRemoveDirectors")}
                        id={`${stakeholder.id}-yes-appointOrRemoveMajorityOfBoardOfDirectors`}
                        label="Yes"
                    />
                    <RadioButton
                        checked={!stakeholder.rightToAppointAndRemoveDirectors}
                        onChange={onRadioChange(stakeholder, "rightToAppointAndRemoveDirectors", true)}
                        id={`${stakeholder.id}-no-appointOrRemoveMajorityOfBoardOfDirectors`}
                        label="No"
                    />
                </div>
            </div>
            {stakeholder.type === "PersonSignificantControl" && (
                <StakeholderActionButtons
                    onViewDetails={toggleEditModalOpen(stakeholder)}
                    onRemove={toggleRemoveStakeholderModal(stakeholder)}
                    dataTestId={`${stakeholder.id}-actions`}
                />
            )}
            <hr />
        </div>
    );

    return (
        <div className="md:tw-w-3/4 lg:tw-w-2/3">
            <h3 className="text-header" data-testid="title">Person of Significant Control</h3>
            <FormLabel className="text-accent d-block mb-4">
                Confirm company PSCs
            </FormLabel>
            {/* Directors */}
            {context.formState.stakeholders
                .filter(x => x.type === "Director")
                .sort((x, y) => sort<Stakeholder>(x, y, "typeCount", true))
                .map(director => renderListing(director))}
            {/* Shareholders */}
            {context.formState.stakeholders
                .filter(x => x.type === "Shareholder")
                .sort((x, y) => sort<Stakeholder>(x, y, "typeCount", true))
                .map(shareholder => renderListing(shareholder))}
            {/* Persons of Significant control */}
            {context.formState.stakeholders
                .filter(x => x.type === "PersonSignificantControl")
                .sort((x, y) => sort<Stakeholder>(x, y, "typeCount", true))
                .map(shareholder => renderListing(shareholder))}
            <div className="tw-my-10">
                <p className="tw-text-sm text-accent">
                    We have taken your shareholding information and used it to pre select your companies PSCs. You can change this information.
                    By selecting the tick box that applies. Generally, if you own more than 25% of a company, you will be classified as a PSC.
                </p>
                <p className="tw-text-sm text-accent">
                    If you need any further information about PSCs you can call us or use the following link to go to Companies House information page&nbsp;
                    <a href="https://www.gov.uk/guidance/people-with-significant-control-pscs#content" rel="noreferrer" target="_blank" className="text-primary tw-underline">
                        (https://www.gov.uk/guidance/people-with-significant-control-pscs#content)
                    </a>.
                </p>
            </div>
            {/* Add Modal */}
            <PersonalInformationModal
                onFormSubmit={onCreateSubmit}
                isOpen={modalOpen === "create"}
                toggleModal={toggleCreateStakeholderModal}
                stakeholder={activeStakeholder}
                personType="PersonSignificantControl"
                title="Add Person of Significant Control"
                submitButtonText="Add"
            />
            {/* Edit Modal */}
            <PersonalInformationModal
                onFormSubmit={onEditSubmit}
                isOpen={modalOpen === "edit" && !!activeStakeholder}
                toggleModal={toggleEditModalOpen()}
                stakeholder={activeStakeholder}
                personType="PersonSignificantControl"
                title="Edit Person of Significant Control"
                cancelButtonText="Close"
                submitButtonText="Update"
            />
            <ConfirmCancelModal
                isOpen={modalOpen === "remove" && !!activeStakeholder}
                text={activeStakeholder && `Are you sure you want to remove ${activeStakeholder.firstName} ${activeStakeholder.lastName}?`}
                onConfirm={onRemoveSubmit(activeStakeholder)}
                onClose={toggleRemoveStakeholderModal()}
            />
            {context.formState.stakeholders.length < api.configuration.maxNumberStakeholders && (
                <button
                    type="button"
                    className="tw-font-semibold tw-py-3 tw-px-5 tw-border-gray-400 tw-border-2 tw-text-gray-500 tw-rounded-md !tw-outline-0"
                    onClick={toggleCreateStakeholderModal}
                >
                    Add a PSC
                </button>
            )}
        </div>
    );
};

export { PersonSignificantControlForm };
