// This shows how use the plugin infrastructure so we don't end up with one unmanageable blockStyleFn, blockRenderMap, styleMap. It allows us to do anything easily with Draft.js

const createBlockQuotePlugin = (size) => {
    const blockStyleFn = (contentBlock) => {
        if (contentBlock.getType() === "blockquote") {
            return "bg-primary";
        }

        if (contentBlock.getType() === "unordered-list-item") {
            if (size === "lg") {
                return "text-primary large-mode";
            }

            return "text-primary";
        }

        return undefined;
    };

    return {
        blockStyleFn,
    };
};

export { createBlockQuotePlugin };
