import React, { useEffect } from "react";
import { Button } from "reactstrap";

import { UpsellTile } from "../../Components/UpsellTile";
import { useUpsell } from "../../Components/UpsellTile/useUpsell";
import { useCompanyFormationsContext } from "../../Context";
import { useTheme } from "Hooks/useTheme";
import SuazImage from "../../Assets/Images/suaz-image.svg";
import ProformationsImage from "../../Assets/Images/proFormations-image.svg";
import styles from "../CompanyNameForm/styles.module.scss";

export const CompanyNameSuccessForm = () => {
    const context = useCompanyFormationsContext();
    const { getThemeName } = useTheme();
    const { getUpsellsByPage, getUpsellProductIdIfInBasket } = useUpsell();

    useEffect(() => {
        context.setDisableContinueButton(false);
        context.setProceedLabel("Continue to secure it today");
    }, []);

    const upsells = getUpsellsByPage();

    return (
        <>
            <div className="d-flex flex-row">
                <div className={styles.info}>
                    <h3 className="text-main mb-4">
                        {getThemeName() === "proFormations"
                            ? <>Apply to form a company today in a couple of minutes for <b>just £9!</b></>
                            : <>Apply to form a company for <b>FREE</b> in just a couple of minutes!</>}
                    </h3>
                    <h5 className="text-primary mb-4"><b>PLUS...</b></h5>
                    <h6 className="text-main mb-4">
                        <b>FREE</b> introduction to hundreds of national companies dedicated to serving new start up businesses
                        like yours, thanks to the <b>Business Support Club.</b>
                    </h6>
                </div>
                <div>
                    <svg className={styles.image}>
                        {getThemeName() === "proFormations" ? <ProformationsImage /> : <SuazImage />}
                    </svg>
                </div>
            </div>
            <h3 className="text-main mb-4">Success!</h3>
            <h1 className="text-muted font-weight-bold font-size-14 rounded mb-4 mt-4">
                Companies House has confirmed that <b>{context.formState.companyName} {context.formState.companyTitle}</b>
                <span className="font-weight-normal"> is available.</span>
            </h1>

            <Button
                color="link"
                className="pl-0 font-weight-bold text-underline"
                onClick={context.navigateToPreviousStep}
            >
                Search another name
            </Button>
            <div className="text-main footerGap">
                <h3 className="font-size-20 ml-2 mt-5">Boost your company with these packages</h3>
                <div className="d-flex align-content-stretch flex-wrap">
                    {upsells.map(upsell => (
                        <UpsellTile key={upsell.upsellId} upsell={upsell} productId={getUpsellProductIdIfInBasket(upsell.products)} />
                    ))}
                </div>
            </div>
        </>);
};
