import React, { useEffect } from "react";

import { UpsellTile } from "../../Components/UpsellTile";
import { useUpsell } from "../../Components/UpsellTile/useUpsell";
import { useCompanyFormationsContext } from "../../Context";
import Checkbox from "Components/Checkbox";
import { useTheme } from "../../Hooks/useTheme";

export const BusinessBankAccountForm = () => {
    const context = useCompanyFormationsContext();
    const {
        getUpsellsByPage,
        getUpsellProductIdIfInBasket,
    } = useUpsell();
    const { getBusinessName } = useTheme();
    const upsells = getUpsellsByPage();

    useEffect(() => {
        context.setDisableContinueButton(context.hasCashPlusProduct && (
            !context.formState.hasConfirmedSubmissionApplication
            || !context.formState.hasAcceptedBankingTerms
            || !context.formState.acknowledgedFSCS));
    }, [context.formState, context.config?.upsells, context.hasCashPlusProduct]);

    const toggleHasConfirmedSubmissionApplication = () => {
        context.changeFormState("hasConfirmedSubmissionApplication", !context.formState.hasConfirmedSubmissionApplication);
    };

    const toggleAcceptedBankingTerms = () => {
        context.changeFormState("hasAcceptedBankingTerms", !context.formState.hasAcceptedBankingTerms);
    };

    const toggleAcceptFscs = () => {
        context.changeFormState("acknowledgedFSCS", !context.formState.acknowledgedFSCS);
    };

    return (
        <>
            <h3 className="text-header">Business Bank Account</h3>
            <div className="text-main footerGap">
                <p data-testid="bank-account-content">
                    Your new business will need a bank account. Unlike other formation agents, we only have one recommendation.
                    We think that this is the perfect account for your new business. And when you apply through {getBusinessName()}&nbsp;
                    <span className="tw-font-bold">there are no monthly fees!</span>
                </p>
                <div className="d-flex align-content-stretch flex-wrap">
                    {upsells.map(upsell => (
                        <UpsellTile
                            key={upsell.upsellId}
                            upsell={upsell}
                            productId={getUpsellProductIdIfInBasket(upsell.products)}
                            testId={`${upsell.upsellName}-${upsell.upsellId}`}
                        />
                    ))}
                </div>
                {context.hasCashPlusProduct && (
                    <>
                        <p className="tw-pt-4">
                            <span className="tw-italic">I,</span>
                            <span className="tw-font-bold"> {`${context.primaryStakeholder?.firstName} ${context.primaryStakeholder?.lastName}`}</span>
                            <span className="tw-italic"> am authorised to submit this application on behalf of</span>
                            <span className="tw-font-bold"> {context.formState.companyName} {context.formState.companyTitle}</span>
                        </p>
                        <p>
                            <i>
                                Only a director, or person with permission of a director, can submit this application.
                                By clicking “I agree to apply for a Cashplus Business Bank Account” you agree to the&nbsp;
                                <a
                                    href="https://www.cashplus.com/media/2841/27779-bca-bus-go-final-marcortct48150423-20230509v3.pdf"
                                    rel="noopener noreferrer"
                                    className="text-underline text-bscPrimary"
                                    target="_blank"
                                >Terms and Conditions
                                </a>
                                .&nbsp;
                                As part of the application, Advanced Payment Solutions Limited
                                (APS), trading as Cashplus Bank, will need to verify your identity which will involve
                                APS checking your personal details against third-party databases.
                            </i>
                        </p>
                        <Checkbox
                            id="hasConfirmedSubmissionApplication"
                            disabled={context.isLoading}
                            checked={!!context.formState.hasConfirmedSubmissionApplication}
                            onChange={toggleHasConfirmedSubmissionApplication}
                            label="I confirm that I am authorised to submit this application on behalf of the company in my capacity as director/person authorised"
                            className="mt-2"
                            testId="hasConfirmedSubmissionApplication"
                        />
                        <Checkbox
                            id="bankingTermsAccepted"
                            disabled={context.isLoading}
                            checked={!!context.formState.hasAcceptedBankingTerms}
                            onChange={toggleAcceptedBankingTerms}
                            label={
                                <div>
                                    &nbsp;I agree to apply for a Cashplus Business Account&nbsp;
                                </div>
                            }
                            className="mt-4"
                            testId="bankingTermsAccepted"
                        />
                        <div className="tw-flex tw-mt-4">
                            <Checkbox
                                id="fscsAccepted"
                                disabled={context.isLoading}
                                checked={!!context.formState.acknowledgedFSCS}
                                onChange={toggleAcceptFscs}
                                label="I acknowledge the cover of the"
                                testId="fscsAccepted"
                            />
                            <div className="tw-flex !tw--ml-7 tw-mt-0.5">
                                <a
                                    href="https://www.cashplus.com/fscs"
                                    rel="noopener noreferrer"
                                    className="text-underline text-bscPrimary"
                                    target="_blank"
                                >
                                    Financial Services Compensation Scheme
                                </a>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>);
};
