import React, { useEffect } from "react";

import history from "../../appHistory";
import { getBrowserId } from "Utils/localStorage";
import { useCompanyFormationsContext } from "../../Context";

declare global {
    interface Window {
        rg4js(key: string, payload: any);
    }
}

const RaygunLogging = () => {
    const { config, formState } = useCompanyFormationsContext();
    const browserId = getBrowserId();

    useEffect(() => {
        if (config?.configuration.raygunApiKey) {
            window.rg4js("apiKey", config?.configuration.raygunApiKey);
            window.rg4js("enableCrashReporting", true);
            window.rg4js("withCustomData", formState);

            history.listen(location => window.rg4js("trackEvent", { type: "pageView", path: location.pathname }));
        }
    }, [config?.configuration.raygunApiKey, browserId]);

    return (<></>);
};

export {
    RaygunLogging,
};
