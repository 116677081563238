import React, { useEffect, useState } from "react";
import { FormGroup, Label } from "reactstrap";
import moment from "moment";
import classnames from "classnames";

import { FormLabel } from "../FormLabel";
import Textbox from "../Textbox";

import styles from "./styles.module.scss";

interface Props {
    currentDate?: string;
    changeDate: (valid: boolean, date: Date) => void;
    label?: React.ReactNode | string;
    disabled?: boolean;
    min?: Date | string | null;
    max?: Date | string | null;
    dataTestId?: string;
}

const DayMonthYearField = ({
    currentDate,
    changeDate,
    label,
    disabled = false,
    min,
    max,
    dataTestId,
}: Props) => {
    const parseDate = (value: Date | string | null | undefined): Date | undefined => {
        if (value) {
            const type = typeof value;

            if (type === "string") {
                return moment(value as string)
                    .toDate();
            }
        }

        return value as Date;
    };

    const [selectedDay, setSelectedDay] = useState<string | undefined>(undefined);
    const [selectedMonth, setSelectedMonth] = useState<string | undefined>(undefined);
    const [selectedYear, setSelectedYear] = useState<string | undefined>(undefined);
    const maxDate = parseDate(max);
    const minDate = parseDate(min);
    const dayRegex = "^(0[1-9]|[12]\\d|3[01])$";
    const monthRegex = "^(0[1-9]|1[012])$";
    const yearRegex = "^(19[0-9][0-9]|20[0-9][0-9])$";
    const dayTest = new RegExp(dayRegex);
    const monthTest = new RegExp(monthRegex);
    const yearTest = new RegExp(yearRegex);

    useEffect(() => {
        const date = parseDate(currentDate);
        if (date) {
            const current = moment(date);
            setSelectedDay(current.format("DD"));
            setSelectedMonth(current.format("MM"));
            setSelectedYear(current.format("YYYY"));
        }
    }, [currentDate]);

    const isValidDate = (day: string | undefined, month: string | undefined, year: string | undefined) => {
        if (day && dayTest.test(day)
            && month && monthTest.test(month)
            && year && yearTest.test(year)) {
            const current = parseDate(`${year}-${month}-${day}`);
            if (current !== undefined && moment(current)
                .isValid()) {
                if (((maxDate && current <= maxDate) && (minDate && current >= minDate))) {
                    return true;
                }
            }
        }
        return false;
    };

    const updateDay = (e) => {
        setSelectedDay(e.target.value);
        const isValid = isValidDate(e.target.value, selectedMonth, selectedYear);
        changeDate(isValid, moment(`${selectedYear}-${selectedMonth}-${e.target.value}`)
            .toDate());
    };

    const updateMonth = (e) => {
        setSelectedMonth(e.target.value);
        const isValid = isValidDate(selectedDay, e.target.value, selectedYear);
        changeDate(isValid, moment(`${selectedYear}-${e.target.value}-${selectedDay}`)
            .toDate());
    };

    const updateYear = (e) => {
        setSelectedYear(e.target.value);
        const isValid = isValidDate(selectedDay, selectedMonth, e.target.value);
        changeDate(isValid, moment(`${e.target.value}-${selectedMonth}-${selectedDay}`)
            .toDate());
    };

    return (
        <FormGroup className="d-flex flex-row">
            {label && <Label>{label}</Label>}
            <Textbox
                label={<FormLabel>Day</FormLabel>}
                name="day"
                type="text"
                value={selectedDay}
                error=""
                onChange={updateDay}
                data-testid={`${dataTestId}-day`}
                placeholder="DD"
                className={classnames(styles.dateField, "mr-3")}
                disabled={disabled}
                regex={dayRegex}
                isInvalidError="Invalid"
                isRequired
                isRequiredError="Invalid"
            />
            <Textbox
                label={<FormLabel>Month</FormLabel>}
                name="month"
                type="text"
                value={selectedMonth}
                error=""
                onChange={updateMonth}
                data-testid={`${dataTestId}-month`}
                placeholder="MM"
                className={classnames(styles.dateField, "mr-3")}
                disabled={disabled}
                regex={monthRegex}
                isInvalidError="Invalid"
                isRequired
                isRequiredError="Invalid"
            />
            <Textbox
                label={<FormLabel>Year</FormLabel>}
                name="year"
                type="text"
                value={selectedYear}
                error=""
                onChange={updateYear}
                data-testid={`${dataTestId}-year`}
                placeholder="YYYY"
                className={classnames(styles.dateField)}
                disabled={disabled}
                regex={yearRegex}
                isInvalidError="Invalid"
                isRequired
                isRequiredError="Invalid"
            />
        </FormGroup>
    );
};

export { DayMonthYearField };
