import React, { useEffect, useState } from "react";
import { Form } from "reactstrap";

import { isValidNumber, validateAddress, validateEmail } from "Utils/validator";
import { useCompanyFormationsContext } from "../../Context";
import { FormLabel } from "../../Components/FormLabel";
import { TextboxWithConfirmation } from "../../Components/TextboxWithConfirmation";
import Textbox from "../../Components/Textbox";
import { SecurityQuestionKeys } from "../SecurityInformationForm/SecurityQuestionKeys";
import { AddressInput } from "../../Components/AddressInput";
import { AddressLookupState } from "../../Components/AddressLookup/address.instance";
import RadioButton from "../../Components/RadioButton";
import { Address, UkAddressCountry } from "../../Types/Address";

export const ContactInformationForm = () => {
    const context = useCompanyFormationsContext();
    const [error, setError] = useState<string | null>(null);
    const [allowContinue, setAllowContinue] = useState(!!context.formState.emailAddress);
    const [isValidAddress, setIsValidAddress] = useState(validateAddress(context.primaryStakeholder.address));
    const [addressState, setAddressState] = useState(isValidAddress ? AddressLookupState.ReadOnly : AddressLookupState.Search);

    const isInvalid = () => !validateEmail(context.formState.emailAddress)
        || !context.formState.emailAddressConfirmed
        || !context.primaryStakeholder.address?.country
        || !isValidAddress || !isValidNumber(context.primaryStakeholder.mobileNumber || "");

    useEffect(() => {
        setAllowContinue(!isInvalid());
        context.setDisableContinueButton(isInvalid());

        return () => {
            setAllowContinue(true);
            context.setDisableContinueButton(false);
        };
    }, [isInvalid]);

    const validate = (value: string) => {
        if (value === "" || validateEmail(value)) {
            setError("");
        } else {
            setError("Please enter a valid email address");
        }
    };

    const updatePhoneNumber = (event: any) => {
        context.changePrimaryStakeholderState("mobileNumber", event.target.value);
        if (!context.formState.question1
            || context.formState.question1 === SecurityQuestionKeys.TelephoneNumber) {
            // if security questions not yet done, set first to this phone number
            context.changeFormState("question1", SecurityQuestionKeys.TelephoneNumber);
            context.changeFormState("question1Answer", event.target.value);
        }
    };

    const updateEmailAddress = (event: any) => {
        context.changeFormState("emailAddress", event?.target?.value);
        validate(event?.target?.value);
    };

    const confirmedChange = (confirmed: boolean) => {
        context.changeFormState("emailAddressConfirmed", confirmed);
    };

    const onFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if (allowContinue) {
            context.navigateToNextStep();
        }
    };

    const onSaveSearchedAddress = (newAddress: Address) => {
        context.changePrimaryStakeholderState("address", newAddress);
        setIsValidAddress(true);
        setAddressState(AddressLookupState.ReadOnly);
    };

    const onSwitchToManualAddressClick = () => {
        setIsValidAddress(false);
        setAddressState(AddressLookupState.Manual);
    };

    const onManualAddressChange = (newAddress: Address, isValid: boolean) => {
        setIsValidAddress(isValid);
        context.changePrimaryStakeholderState("address", newAddress);
    };

    const onSaveManualAddressClick = () => setAddressState(AddressLookupState.ReadOnly);

    const onCancelManualAddressClick = () => setAddressState(AddressLookupState.Search);

    const onEditSavedAddressClick = () => setAddressState(AddressLookupState.Manual);

    const onRemoveSavedAddressClick = () => {
        context.changePrimaryStakeholderState("address", null);
        setAddressState(AddressLookupState.Search);
    };

    const validatePhoneNumber = (answer: string) => {
        if (answer !== "") {
            return !isValidNumber(answer) ? "Please enter a valid phone number" : "";
        }
        return "";
    };

    return (
        <>
            <h3 className="text-header">Contact Information</h3>
            <FormLabel className="text-accent d-block mb-4">Your contact information will be submitted to Companies House and is required to complete the formation
                of your company.
            </FormLabel>
            <Form onSubmit={onFormSubmit} className="footerGap">
                <h5 className="text-header mt-5">Email address</h5>
                <TextboxWithConfirmation
                    label={
                        <FormLabel className="text-accent d-block mb-4">
                            Your email address will also be your username for Business Support Club. You can use this to login,
                            access important company documents and talk to hundreds of potential suppliers.
                        </FormLabel>
                    }
                    name="email"
                    value={context.formState.emailAddress || ""}
                    error={error || ""}
                    onChange={updateEmailAddress}
                    onConfirmedChange={confirmedChange}
                    autoFocus
                    dataTestId="emailAddressInput"
                    placeholder="email address"
                    confirmed={context.formState.emailAddressConfirmed}
                    isRequired
                />
                <Textbox
                    className="mt-4"
                    label={<FormLabel>Mobile Number</FormLabel>}
                    value={context.primaryStakeholder.mobileNumber}
                    onChange={updatePhoneNumber}
                    error={validatePhoneNumber(context.primaryStakeholder.mobileNumber || "")}
                    placeholder="Enter your mobile number"
                    data-testid="phoneNumberInput"
                />
                <h3 className="text-header mt-4">Personal Address</h3>
                <FormLabel className="text-accent d-block mb-4">Your personal address will be submitted to Companies House and is required to complete the formation
                    of your company.
                </FormLabel>
                <AddressInput
                    address={context.primaryStakeholder.address ?? {} as Address}
                    addressState={addressState}
                    isValidAddress={isValidAddress}
                    onSaveSearchedAddress={onSaveSearchedAddress}
                    onEditSavedAddressClick={onEditSavedAddressClick}
                    onRemoveSavedAddressClick={onRemoveSavedAddressClick}
                    onManualAddressChange={onManualAddressChange}
                    onSwitchToManualAddressClick={onSwitchToManualAddressClick}
                    onSaveManualAddressClick={onSaveManualAddressClick}
                    onCancelManualAddressClick={onCancelManualAddressClick}
                />
                {addressState === AddressLookupState.ReadOnly && isValidAddress
                    && (
                        <div className="mt-3">
                            <h5 className="text-header">What country is this address in?</h5>
                            {Object.keys(UkAddressCountry)
                                .map(countryValue => (
                                    <div key={`addressCountry${countryValue}`}>
                                        <RadioButton
                                            checked={(context.primaryStakeholder.address
                                                && context.primaryStakeholder.address.country === UkAddressCountry[countryValue]) as boolean}
                                            onChange={() => context.changePrimaryStakeholderState("address", {
                                                ...context.primaryStakeholder.address,
                                                country: UkAddressCountry[countryValue],
                                            })}
                                            label={UkAddressCountry[countryValue]}
                                            name="addressCountry"
                                            id={`addressCountry${countryValue}`}
                                            className="my-3"
                                        />
                                    </div>
                                ))}
                        </div>
                    )}
                <p className="p-3" />
            </Form>
        </>
    );
};
