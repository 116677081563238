import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Card, Form } from "reactstrap";
import classnames from "classnames";

import Textbox from "Components/Textbox";
import { FormLabel } from "Components/FormLabel";
import { useCompanyFormationsContext } from "../../Context";

import { questions, SecurityQuestionKeys } from "./SecurityQuestionKeys";
import styles from "./styles.module.scss";

export const SecurityInformationForm = () => {
    const context = useCompanyFormationsContext();
    const minCharErrorMessage = "Minimum length: 3 characters.";
    const invalidNumberMessage = "Phone number entered is invalid.";
    const [question1Valid, setQuestion1Valid] = useState(false);
    const [question2Valid, setQuestion2Valid] = useState(false);
    const [question3Valid, setQuestion3Valid] = useState(false);

    const isValidNumber = (answer: string) => {
        if (answer.length < 9 || answer.length > 13) {
            return false;
        }

        // 0 or 1 '+'
        // 1 or more numbers
        const regex = new RegExp("^[+]?[0-9]{9,12}");
        const isMatch = regex.test(answer);

        return (isMatch);
    };

    const isValidLength = (answer: string) => (
        !(answer.length < 3)
    );

    const validateAnswer = (key: "question1" | "question2" | "question3", answer: string) => {
        const ans = answer.replace(/\s/g, "");
        // do not validate if an answer is yet to be typed
        if (ans !== "") {
            if (context.formState[key] === SecurityQuestionKeys.TelephoneNumber) {
                if (!isValidNumber(ans)) {
                    return invalidNumberMessage;
                }
            }

            if (!isValidLength(ans)) {
                return minCharErrorMessage;
            }
        }

        return "";
    };

    const valueChanged = (question: "question1" | "question2" | "question3", answer: "question1Answer" | "question2Answer" | "question3Answer", value: string) => {
        context.changeFormState(answer, value);
        if (context.formState[question] === SecurityQuestionKeys.TelephoneNumber) {
            context.changePrimaryStakeholderState("mobileNumber", value);
        }
    };

    const allowContinue = () => {
        // @ts-ignore
        setQuestion1Valid(context.formState.question1Answer && (validateAnswer("question1", context.formState.question1Answer) === ""));
        // @ts-ignore
        setQuestion2Valid(context.formState.question2Answer && (validateAnswer("question2", context.formState.question2Answer) === ""));
        // @ts-ignore
        setQuestion3Valid(context.formState.question3Answer && (validateAnswer("question3", context.formState.question3Answer) === ""));

        return question1Valid && question2Valid && question3Valid;
    };

    useEffect(() => {
        context.setDisableContinueButton(!allowContinue());
        if (!context.formState.question1) {
            context.changeFormState("question1", SecurityQuestionKeys.TelephoneNumber);
        }
        if (!context.formState.question2) {
            context.changeFormState("question2", SecurityQuestionKeys.FathersFirstName);
        }
        if (!context.formState.question3) {
            context.changeFormState("question3", SecurityQuestionKeys.TownOfBirth);
        }
    });

    return (
        <div className="pb-3">
            <h3 className="text-header">Security Information</h3>
            <FormLabel className="text-accent d-block mb-4">Companies House requires submission of 3 pieces of security
                information in case you need to recover your account. This information remains private and will never be used
                for any other purpose.
            </FormLabel>
            <Form className="mb-2 footerGap">
                <Card data-testid="question1card" className={classnames(question1Valid && "border-success", "m-auto h-100 p-3")}>
                    {question1Valid && (<i className={classnames(styles.isValid, "text-success position-relative fa fa-check-circle fa-2x")} />)}
                    <FormLabel className="h6">Question 1</FormLabel>
                    <Select
                        placeholder="Select question..."
                        options={questions.filter(x => x.value !== context.formState.question2 && x.value !== context.formState.question3)}
                        onChange={(event) => context.changeFormState("question1", event.value)}
                        className="my-3"
                        value={questions.filter(x => x.value === context.formState.question1)}
                    />
                    <Textbox
                        label={<FormLabel>Answer</FormLabel>}
                        value={context.formState.question1Answer || ""}
                        onChange={(e) => valueChanged("question1", "question1Answer", e.target.value)}
                        error={validateAnswer("question1", context.formState.question1Answer || "")}
                        data-testid="answer1"
                    />
                </Card>
                <Card data-testid="question2card" className={classnames(question2Valid && "border-success", "mt-3 h-100 p-3")}>
                    {question2Valid && (<i className={classnames(styles.isValid, "text-success position-relative fa fa-check-circle fa-2x")} />)}
                    <FormLabel className="h6">Question 2</FormLabel>
                    <Select
                        placeholder="Select question..."
                        options={questions.filter(x => x.value !== context.formState.question1 && x.value !== context.formState.question3)}
                        onChange={(event) => context.changeFormState("question2", event.value)}
                        className="my-3"
                        value={questions.filter(x => x.value === context.formState.question2)}
                    />
                    <Textbox
                        label={<FormLabel>Answer</FormLabel>}
                        value={context.formState.question2Answer || ""}
                        onChange={(e) => valueChanged("question2", "question2Answer", e.target.value)}
                        error={validateAnswer("question2", context.formState.question2Answer || "")}
                        data-testid="answer2"
                    />
                </Card>
                <Card data-testid="question3card" className={classnames(question3Valid && "border-success", "mt-3 h-100 p-3")}>
                    {question3Valid && (<i className={classnames(styles.isValid, "text-success position-relative fa fa-check-circle fa-2x")} />)}
                    <FormLabel className="h6">Question 3</FormLabel>
                    <Select
                        placeholder="Select question..."
                        options={questions.filter(x => x.value !== context.formState.question1 && x.value !== context.formState.question2)}
                        onChange={(event) => context.changeFormState("question3", event.value)}
                        className="my-3"
                        value={questions.filter(x => x.value === context.formState.question3)}
                    />
                    <Textbox
                        label={<FormLabel>Answer</FormLabel>}
                        value={context.formState.question3Answer || ""}
                        onChange={(e) => valueChanged("question3", "question3Answer", e.target.value)}
                        error={validateAnswer("question3", context.formState.question3Answer || "")}
                        data-testid="answer3"
                    />
                </Card>
            </Form>
        </div>
    );
};
