import uuid from "uuid";
import store from "store";

const browserIdKey = "__tbsc.browserid";
const cookiesAcceptedKey = "__tbsc.cookies.accepted";

const getBrowserId = (): string => store.get(browserIdKey);
const createBrowserId = (): void => store.set(browserIdKey, uuid());

const setCookiesAccepted = (): void => store.set(cookiesAcceptedKey, true);
const removeCookiesAccepted = (): void => store.remove(cookiesAcceptedKey);

export {
    getBrowserId,
    createBrowserId,
    setCookiesAccepted,
    removeCookiesAccepted,
};
