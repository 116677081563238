import api from "Utils/api";
import { BasketResponseProps } from "../../Pages/SummaryForm/BasketResponseProps";

export interface CompanyFormationCheckoutRequest
{
    companyFormationsProductId: string;
    companyFormationFieldAnswers;
    upsellProductIds: string[];
}

const checkout = (companyFormationCheckoutRequest: CompanyFormationCheckoutRequest):
    Promise<BasketResponseProps> => api.post("company-formations/checkout", companyFormationCheckoutRequest);

export {
    checkout,
};
